<h2 mat-dialog-title>{{ data?.title }}</h2>
<mat-dialog-content class="mat-typography">

  <p *ngIf="data.contentPlainText">
    {{ data.contentPlainText }}
  </p>

  <div *ngIf="data.contentHtml" [innerHTML]="data.contentHtml"></div>

</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>