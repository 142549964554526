<div class="grid-main-content">
  <main class="item-main">

    <div class="common-intro">
      <h1 class="common-intro__hdr">Personal Details</h1>
      <p *ngIf="!isQuotezone" class="common-intro__text">Lead traveller address and contact details.</p>
    </div>

    <mat-card *ngIf="showExternalLoadError">
      <mat-card-title>
        Error
      </mat-card-title>
      <mat-card-content>
        <p>An error occurred while loading your policy. Cannot continue.</p>
        <p>Please call the number at the top of this page for further assistance.</p>
      </mat-card-content>
    </mat-card>

    <ng-container *ngIf="certificate">

      <form [formGroup]="personalDetailForm">

        <div *ngIf="isQuotezone" formArrayName="clientNames" class="client-name-container">
          <h2 class="personal-hdr">Traveller Names</h2>
          <div *ngFor="let client of clientNamesFormArray.controls; index as i" [formGroupName]="i" class="client-name-item">
            <div class="client-name-item__hdr">
              <h3>{{ i === 0 ? 'Lead Traveller' : 'Additional Traveller ' + (i + 1) }} ({{ client.get('dateOfBirth').value | date: 'dd/MM/yyyy' }})</h3>
            </div>
            <div class="client-name-item__fields">
              <mat-form-field>
                <mat-label>Title</mat-label>
                <mat-select tabindex="0" formControlName="title" placholder="Title">
                  <mat-option value=""></mat-option>
                  <mat-option *ngFor="let title of clientTitles" [value]="title">{{ title }}</mat-option>
                </mat-select>
                <mat-error *ngIf="client.get('title').errors?.required">Title is required.</mat-error>
              </mat-form-field>
              <mat-form-field>
                <mat-label>First Name</mat-label>
                <input [id]="'firstName__' + i" tabindex="0" formControlName="firstName" type="text" matInput placeholder="" />
                <mat-error *ngIf="client.get('firstName').errors?.required">First name is required.</mat-error>
                <mat-error *ngIf="client.get('firstName').errors?.maxlength">First name must not exceed 50 characters.</mat-error>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Surname</mat-label>
                <input [id]="'lastName__' + i" tabindex="0" formControlName="lastName" type="text" matInput placeholder="" />
                <mat-error *ngIf="client.get('lastName').errors?.required">Surname is required.</mat-error>
                <mat-error *ngIf="client.get('lastName').errors?.maxlength">Surname must not exceed 50 characters.</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        
        <h2 *ngIf="isQuotezone" class="personal-hdr">Lead Traveller Address and Contact Details</h2>
        <app-address-entry [postcodeSearchFormGroupName]="postcodeSearchFormGroupName"
                          [addressFormGroupName]="addressFormGroupName">
        </app-address-entry>
        
        <ng-container formGroupName="contactAndMarketing">
          <div class="form-group-container" >
            <div class="form-item">
              <mat-form-field>
                <input id="telDaytime" tabindex="0" formControlName="telDaytime" type="text" matInput placeholder="Telephone" />
                <mat-error *ngIf="personalDetailForm.get('contactAndMarketing.telDaytime').errors?.required">Please enter the telephone number for the lead traveller.</mat-error>
                <mat-error *ngIf="personalDetailForm.get('contactAndMarketing.telDaytime').errors?.pattern">Should contain only numbers and not exceed 15 characters.</mat-error>
              </mat-form-field>
            </div>
            <div class="form-item">
              <mat-form-field>
                <input id="email" tabindex="0" formControlName="email" type="email" matInput placeholder="Email" />
                <mat-error *ngIf="personalDetailForm.get('contactAndMarketing.email').errors?.required">Please enter an email address for the lead traveller.</mat-error>
                <mat-error *ngIf="personalDetailForm.get('contactAndMarketing.email').errors?.email">Please check the email address. It appears to be invalid.</mat-error>
                <mat-error *ngIf="personalDetailForm.get('contactAndMarketing.email').errors?.maxlength">Please check the email address. Must not exceed 254 characters.</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="common-char-width-limit">
            <div class="opt-statement" [innerHTML]="optInStatementContent"></div>
            <mat-checkbox tabindex="0"
                          formControlName="optInMail">
              <span class="opt-checkbox" [innerHTML]="optInStatementCheckboxLabelContent"></span>
            </mat-checkbox>
            <div class="opt-checkbox-divider" role="presentation"></div>
            <mat-checkbox tabindex="0"
                          formControlName="privacyStatementConsent">
              <span class="opt-checkbox" [innerHTML]="privacyStatementCheckboxLabelContent"></span>
            </mat-checkbox>
            <p class="error-text" *ngIf="personalDetailForm.get('contactAndMarketing.privacyStatementConsent').errors && personalDetailFormSubmitted === true">Please confirm you agree to our privacy statement.</p>
          </div>
        </ng-container>

        <div *ngIf="showMapsInfoMessage" class="maps-message-container common-char-width-limit">
          <p class="maps-message-text">The Money &amp; Pensions Service provide a directory of travel insurers that can offer specialist quotes if anyone being insured is living with a serious medical condition. If you would like to find out more call 0800 138 7777 or visit https://www.moneyhelper.org.uk/en/everyday-money/insurance/travel-insurance-directory</p>
        </div>
    
        <mat-divider class="common-margin-md-y"></mat-divider>
    
        <div class="primary-actions-container common-margin-lg-y">
          <div *ngIf="personalDetailForm.status === 'INVALID' && personalDetailFormSubmitted" class="card-error">
            <p class="error-text">Please review any highlighted fields above before continuing.</p>
          </div>
          <div class="primary-actions">
            <button *ngIf="showBackBtn === true" tabindex="0" class="primary-actions__btn" type="button" mat-raised-button aria-label="Back" (click)="backOnClick()"><mat-icon inline="true" aria-hidden="true">chevron_left</mat-icon>Back</button>
            <button *ngIf="showSaveBtn === true" id="saveBtn" tabindex="0" class="primary-actions__btn" type="button" mat-raised-button color="accent" aria-label="Save" (click)="saveOnClick(true)">Save</button>
            <button id="continueBtn" tabindex="0" class="primary-actions__btn" type="button" mat-raised-button color="primary" aria-label="Continue" (click)="saveOnClick(false)">Continue<mat-icon inline="true" aria-hidden="true">chevron_right</mat-icon></button>
          </div>
        </div>
    
      </form>

    </ng-container>

  </main>
  <aside class="item-aside">
    <app-campaign-logo></app-campaign-logo>
    <app-sidebar-summary [displayFinancials]="true" [displayMedicalScreenings]="true"></app-sidebar-summary>
  </aside>
</div>