export class SchemeCoverAdditional {

  public sectionId: number;

  public limit: number;

  public nameOverride: string;

  public descriptionHtml: string;

  public dataValueOverride: string;
  
}