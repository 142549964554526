import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { AppConfigService } from '../services/app-config.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '../services/notification.service';
import { PolicyService } from '../services/policy.service';
import { PaymentService } from '../services/payment.service'
import { CampaignService } from '../services/campaign.service';
import { TrackingService } from '../services/tracking.service';
import { ProgressIndicatorService } from '../services/progress-indicator.service';
import { Certificate } from '../models/magenta/certificate';
import { CertificateStatus } from '../models/magenta/certificate-status';
import { SupportedPaymentMethod } from '../models/supported-payment-method';
import { CytiData } from '../models/tracking/cyti-data';
import { ByExternalRefRequest } from '../models/dto/get-policy/by-external-ref-request';
import { ContentSnippet } from '../models/content-snippet';
import { Address } from '../models/magenta/address';
import { BillingAddress } from '../models/dto/payment/billing-address';
import { CardPaymentRequest } from '../models/dto/payment/card-payment-request';
import { CardPaymentResponse } from '../models/dto/payment/card-payment-response';
import { PayPalPaymentRequest } from '../models/dto/payment/paypal-payment-request';
import { PayPalPaymentResponse } from '../models/dto/payment/paypal-payment-response';
import { PayPalPaymentResumeRequest } from '../models/dto/payment/paypal-payment-resume-request';
import { PayPalPaymentResumeResponse } from '../models/dto/payment/paypal-payment-resume-response';
import { ApplePayPaymentRequest } from '../models/dto/payment/apple-pay-payment-request';
import { ApplePayPaymentResponse } from '../models/dto/payment/apple-pay-payment-response';
import { ApplePayValidateMerchantRequest } from '../models/dto/payment/apple-pay-validate-merchant-request';
import { ApplePayValidateMerchantResponse } from '../models/dto/payment/apple-pay-validate-merchant-response';
import { ThreeDsResumeOutcome } from '../models/dto/payment/threeds-resume-outcome';
import { TransientThreeDSecureData } from '../models/transient-three-d-secure-data';
import { ByRecentlyPurchasedRequest } from '../models/dto/get-policy/by-recently-purchased-request';
import { ServerErrorInfo } from '../models/dto/payment/server-error-info';
import { MatDialog } from '@angular/material/dialog';
import { CommonDialogData } from '../models/common-dialog-data';
import { CommonDialogComponent } from '../common-dialog/common-dialog.component';
import { Subscription } from 'rxjs';
import { IsoCountry } from '../models/iso-country';
import { IS_ENV_PROD_MODE } from '../models/environment-token';


declare global {
  interface Window { ApplePaySession: any }
}


@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit, OnDestroy {

  public paymentForm = this._formBuilder.group({
    paymentMethod: new FormControl<SupportedPaymentMethod>(SupportedPaymentMethod.Card, {
      validators: [Validators.required],
      nonNullable: true
    }),
    card: this._formBuilder.group({
      cardHolderName: new FormControl<string | null>(null),
      expiryDate: new FormControl<string | null>(null),
      expiryMonth: new FormControl<string | null>(null),
      expiryYear: new FormControl<string | null>(null),
      cardType: new FormControl<string | null>(null),
      pan: new FormControl<string | null>(null),
      issueNumber: new FormControl<string | null>(null),
      cv2: new FormControl<string | null>(null),
      startDate: new FormControl<string | null>(null)
    }),
    usePolicyForBillingAddress: new FormControl<boolean>(true, {
      nonNullable: true
    }),
    postcodeSearch: this._formBuilder.group({
      postcode: new FormControl<string | null>(null, {
        validators: [Validators.minLength(6), Validators.maxLength(8)]
      }),
      postcodeLookupId: new FormControl<number | null>(null)
    }),
    address: this._formBuilder.group({
      address1: new FormControl<string | null>(null),
      address2: new FormControl<string | null>(null),
      address3: new FormControl<string | null>(null),
      address4: new FormControl<string | null>(null),
      address5: new FormControl<string | null>(null),
      postcode: new FormControl<string | null>(null)
    }),
    marketingAndReqs: this._formBuilder.group({
      optInMail: new FormControl<boolean>(false, {
        nonNullable: true
      }),
      readHealthReqs: new FormControl<boolean>(false, {
        validators: [Validators.requiredTrue],
        nonNullable: true
      })
    })
  });

  public isEnvProdMode: boolean;
  public isPayPalSupported = false;
  public isApplePaySupported = false;
  public isApplePayAvailable = false;
  public disableSubmit = false;
  public submitBtnText: string;
  public certificate: Certificate;
  public showExternalLoadError = false;
  public showMagentaServiceError = false;
  public showCannotPurchasePolicyError = false;
  public magentaServiceErrorDesc: string;
  public showForm = true;
  public paymentFormSubmitted = false;
  public isCyti = false;
  public cytiPaymentTrackingTag: string;
  public showBackBtn = false;
  public privacyNoticeUrl = '';
  public optInStatementContent = '';
  public optInStatementCheckboxLabelContent = '';
  public insurerDisclaimerContent = '';
  public termsContent = '';
  public expiryMonths = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  public expiryYears: string[];
  public isoCountries: IsoCountry[];
  public policyAddressAsOneLine = '';
  public readonly postcodeSearchFormGroupName = 'postcodeSearch';
  public readonly addressFormGroupName = 'address';

  // Required for enum use in template code.
  public readonly supportedPaymentMethodEnum = SupportedPaymentMethod;

  // Subscriptions.
  private readonly _subscriptions: Subscription[] = [];

  constructor(public dialog: MatDialog,
              private _appConfigService: AppConfigService,
              private _spinnerService: NgxSpinnerService,
              private _notificationService: NotificationService,
              private _router: Router,
              private _activatedRoute: ActivatedRoute,
              private _formBuilder: FormBuilder,
              private _policyService: PolicyService,
              private _paymentService: PaymentService,
              private _campaignService: CampaignService,
              private _trackingService: TrackingService,
              private _progressIndicatorService: ProgressIndicatorService,
              private _window: Window,
              @Inject(IS_ENV_PROD_MODE) private _isEnvProdMode: boolean) {

    this.isEnvProdMode = this._isEnvProdMode;

    // Clear any transient 3DS data.
    this._paymentService.transientThreeDSecureData = null;

    // Set supported payment methods.
    this.isPayPalSupported = this._appConfigService.agent.supportedPaymentMethods.includes(SupportedPaymentMethod.PayPal);
    this.isApplePaySupported = this._appConfigService.agent.supportedPaymentMethods.includes(SupportedPaymentMethod.ApplePay);

    // Load content items.
    this.privacyNoticeUrl = this._appConfigService.agent.privacyNoticeUrl;
    this.optInStatementContent = this._appConfigService.getAgentContentSnippetById('optInStatement').value;
    this.optInStatementCheckboxLabelContent = this._appConfigService.getAgentContentSnippetById('optInStatementCheckboxLabel').value;
    this.insurerDisclaimerContent = this._appConfigService.getAgentContentSnippetById('payment_insurerDisclaimer').value;
    this.termsContent = this._appConfigService.getAgentContentSnippetById('termsOfBusiness').value;
  }

  private policyAddressToOneLine(address: Address): string {
    const addressLines = [address.address1, address.address2, address.address3, address.address4, address.postCode, address.address5];
    const filteredLines = addressLines.filter(addressLine => addressLine?.length > 0);
    return filteredLines.join(', ');
  }

  private initCardExpiryYears(): void {
    const yearCount = 21;
    let startYear = Number(this._appConfigService.agent.dateToday.substring(0, 4));
    const years = new Array(yearCount);

    for(let i = 0; i < yearCount; i++) {
      years[i] = (startYear++).toString();
    }

    this.expiryYears = years;
  }

  private parseExpiryDate(expiryMonthIn: string, expiryYearIn: string): string {
    const monthPart = expiryMonthIn;
    const yearPart = expiryYearIn.substring(2);
    return `${monthPart}${yearPart}`;
  }

  private initSubmitBtnText(): void {
    const paymentMethod = this.paymentForm.controls.paymentMethod.value;

    switch(paymentMethod) {
      case SupportedPaymentMethod.Card: {
        this.submitBtnText = 'Pay by Card';
        break;
      }
      case SupportedPaymentMethod.ApplePay: {
        this.submitBtnText = 'Pay with Apple Pay';
        break;
      }
      case SupportedPaymentMethod.PayPal: {
        this.submitBtnText = 'Pay with PayPal';
        break;
      }
    };
  }

  private initCardValidators(): void {
    const paymentMethod = this.paymentForm.controls.paymentMethod.value;

    switch(paymentMethod) {
      case SupportedPaymentMethod.Card: {
        this.paymentForm.controls.card.controls.cardHolderName.setValidators([Validators.required, Validators.minLength(2), Validators.maxLength(45)]);
        this.paymentForm.controls.card.controls.expiryMonth.setValidators([Validators.required]);
        this.paymentForm.controls.card.controls.expiryYear.setValidators([Validators.required]);
        this.paymentForm.controls.card.controls.pan.setValidators([Validators.required, Validators.pattern('[0-9]{13,19}')]);
        this.paymentForm.controls.card.controls.cv2.setValidators([Validators.required, Validators.pattern('[0-9]{3}')]);
        break;
      }
      case SupportedPaymentMethod.ApplePay:
      case SupportedPaymentMethod.PayPal: {
        this.paymentForm.controls.card.controls.cardHolderName.clearValidators();
        this.paymentForm.controls.card.controls.expiryMonth.clearValidators();
        this.paymentForm.controls.card.controls.expiryYear.clearValidators();
        this.paymentForm.controls.card.controls.pan.clearValidators();
        this.paymentForm.controls.card.controls.cv2.clearValidators();
        break;
      }
    };

    this.paymentForm.controls.card.reset();
  }

  private initBillingAddress(): void {
    const usePolicyAddress = this.paymentForm.controls.usePolicyForBillingAddress.value;

    usePolicyAddress ? this.paymentForm.controls.address.controls.address1.clearValidators() :
                       this.paymentForm.controls.address.controls.address1.setValidators([Validators.required]);

    usePolicyAddress ? this.paymentForm.controls.address.controls.address4.clearValidators() :
                       this.paymentForm.controls.address.controls.address4.setValidators([Validators.required]);

    usePolicyAddress ? this.paymentForm.controls.address.controls.address5.clearValidators() :
                       this.paymentForm.controls.address.controls.address5.setValidators([Validators.required]);
    
    this.paymentForm.controls.address.reset();

    // Populate ISO country array if required.
    if(!usePolicyAddress && !this.isoCountries) {
      this._paymentService.getIsoCountries()
        .subscribe(data => {
          this.isoCountries = data;
        });
    }
  }

  private initCytiRequest(): void {

    const cytiData: CytiData = {
      id: this._activatedRoute.snapshot.queryParamMap.get('Id'),
      cref: this._activatedRoute.snapshot.queryParamMap.get('cref'),
      platformUrl: this._activatedRoute.snapshot.queryParamMap.get('u'),
      token: this._activatedRoute.snapshot.queryParamMap.get('t')
    };

    if(this.certificate && this.certificate.status !== CertificateStatus.IncompleteQuote && this.certificate.externalPolicyReference === cytiData.cref) {
      this.handleCannotPurchasePolicy();
      return;
    }

    const byExternalRefRequest: ByExternalRefRequest = {
      externalReference: cytiData.id,
      externalPolicyReferenceToSave: cytiData.cref
    };

    // Import policy.
    this._policyService.getPolicyByExternalRef(byExternalRefRequest)
      .subscribe(cert => {
        // Set the campaign.
        this._campaignService.setActiveCampaign(cert.campaign, false);

        // Push certificate to service.
        this._policyService.setCertificate(cert);
        this._trackingService.setCytiData(cytiData);

        // Add tracking tag to be rendered. Only needs to be added here for initial page render.
        this.cytiPaymentTrackingTag = this._trackingService.getCytiPaymentTrackingTag(cytiData, cert.overallGross);

        // Fire Distil tracking.
        this._trackingService.gaFireDistilIdentifyCustomerEvent(cert);

        this._spinnerService.hide();
      },
      err => {
        console.log(err);
        this.showExternalLoadError = true;
        this.showForm = false;
        this._spinnerService.hide();
      });

  }

  ngOnInit(): void {

    this.initCardExpiryYears();
    this.initCardValidators();
    this.initSubmitBtnText();
    this.initBillingAddress();

    // Subscribe to certificate.
    this._subscriptions.push(this._policyService.certificate
      .subscribe({
        next: cert => {
          if(cert) {
            this.certificate = cert;
            this.policyAddressAsOneLine = this.policyAddressToOneLine(this.certificate.clients[0].address);
          } 
        }
      })
    );

    this._subscriptions.push(
      this.paymentForm.controls.paymentMethod.valueChanges
        .subscribe(_value => {
          this.initCardValidators();
          this.initSubmitBtnText();
        })
    );

    this._subscriptions.push(
      this.paymentForm.controls.usePolicyForBillingAddress.valueChanges
        .subscribe(_value => {
          this.initBillingAddress();
        })
    );

    // Set Apple Pay availability.
    this._subscriptions.push(
      this._paymentService.isApplePayAvailable()
        .subscribe(value => {
          this.isApplePayAvailable = value;
        })
    );

    // Check for incoming CYTI request params.
    this.isCyti = this._activatedRoute.snapshot.queryParamMap.get('Id') !== null;

    if(this.isCyti) {

      this._spinnerService.show();
      this.initCytiRequest();

    } else {
      // Check for CYTI data previously set.
      this.isCyti = this._trackingService.isCytiDataAvailable();
    }

    // Handle incoming card 3DS resume.
    if(this._activatedRoute.snapshot.queryParamMap.get('threeds') !== null) {

      // Get the URL params.
      const transactionId = this._activatedRoute.snapshot.queryParamMap.get('tid');
      const threeDsResumeOutcome: ThreeDsResumeOutcome = Number(this._activatedRoute.snapshot.queryParamMap.get('outcome'));

      if(threeDsResumeOutcome === ThreeDsResumeOutcome.PolicyStatusIncorrect) {
        this.handleCannotPurchasePolicy();
        return;
      }

      if(threeDsResumeOutcome === ThreeDsResumeOutcome.PaymentFailed) {
        this.handleThreeDSecurePaymentFailed();
        return;
      }

      if(threeDsResumeOutcome === ThreeDsResumeOutcome.PaymentSuccessWithPolicyGenError) {
        this.handleThreeDSecurePaymentSuccessWithPolicyGenError();
        return;
      }

      if(threeDsResumeOutcome === ThreeDsResumeOutcome.PaymentSuccess) {
        this.handleThreeDSecurePaymentSuccess();
        return;
      }

      // Throw error - invalid outcome.
      throw new Error('3DS resume outcome invalid.');

    }

    if(this._activatedRoute.snapshot.queryParamMap.get('paypalresume') !== null) {
      
      this._spinnerService.show();
      this.showForm = false;
      this.paymentForm.controls.paymentMethod.setValue(SupportedPaymentMethod.PayPal);

      const payPalPaymentResumeRequest: PayPalPaymentResumeRequest = {
        agentId: this._appConfigService.agentId,
        certificateId: this.certificate.certificateId,
        transactionId: this._paymentService.getPayPalResumeTransId(),
        noMail: this.isCyti ? this._paymentService.getCytiNoMailVal() : null
      };

      this._paymentService.resumePayPalPayment(payPalPaymentResumeRequest)
        .subscribe(response => {
          this.handlePayPalResumeResponse(response);
        },
        err => {
          console.log(err);
          this._spinnerService.hide();
        });

    }

    if(this._activatedRoute.snapshot.queryParamMap.get('paypalcancel') !== null) {
      this.handlePayPalCancel();
    }

    // Set progress indicator for CYTI traffic.
    if(this.isCyti) {
      this._progressIndicatorService.hideSteps([0, 1, 2, 3, 4]);
    }

    this.showBackBtn = !this.isCyti;

  }

  ngOnDestroy(): void {

    this._subscriptions.forEach(sub => {
      sub.unsubscribe();
    });

  }

  public openCommonDialog(name: string, reasonCode?: string): void {
    
    let dialogTitle: string;
    let dialogHtml: string;
    let dialogPlainText: string;
    let reasonMsg = '';

    switch(name) {
      case 'Cv2': {
        dialogTitle = 'What is a CV2 number?';
        dialogPlainText = null;
        dialogHtml = '<p>The CV2 number is the <strong>last three digits</strong> found on the reverse of your card.</p><img alt="CV2 location on card" src="assets/images/shared/card-cvv-help.svg" width="250" height="178" />';
        break;
      }
      case 'Terms': {
        dialogTitle = 'Terms of Insurance Business Agreement';
        dialogPlainText = null;
        dialogHtml = this.termsContent;
        break;
      }
      case 'CardPaymentFail': {
        dialogTitle = 'Card Payment Failed';
        dialogPlainText = null;
        dialogHtml = '<p>Card payment failed. Please check you have entered your card details correctly.</p>';
        break;
      }
      case 'PayPalPaymentFail': {
        dialogTitle = 'PayPal Payment Failed';
        dialogPlainText = null;
        dialogHtml = '<p>There was a problem processing your payment via PayPal. Please try again, or pay by card.</p>';
        break;
      }
      case 'PayPalResumeFail': {
        dialogTitle = 'PayPal Payment Resume Failed';
        dialogPlainText = null;
        dialogHtml = '<p>There was a problem resuming your payment via PayPal. Please try again, or pay by card.</p>';
        break;
      }
      case 'PayPalPaymentCancel': {
        dialogTitle = 'PayPal Payment Cancelled';
        dialogPlainText = null;
        dialogHtml = '<p>Your PayPal payment was cancelled. Please try again, or pay by card.</p>';
        break;
      }
      case 'ThreeDSecurePaymentFail': {
        dialogTitle = '3D Secure Authentication Failed';
        dialogPlainText = null;
        dialogHtml = '<p>The payment was unsuccessful after attempting 3D Secure authentication. Please try again.</p><p>If problems persist, consider calling us using the number at the top of this page for assistance.</p>';
        break;
      }
      default: {
        throw Error(`Error loading dialog content. Name identifier '${name}' not configured.`);
      }
    }

    // If we have a reasonCode, append any available message to dialog content.
    if(reasonCode) {
      reasonMsg = this._paymentService.getReasonCodeMessage(reasonCode);
    }

    if(reasonMsg.length > 0) {
      dialogHtml = `${dialogHtml}<h3>Additional Information</h3><p>${reasonMsg}</p>`;
    }

    const dialogData: CommonDialogData = {
      title: dialogTitle,
      contentPlainText: dialogPlainText,
      contentHtml: dialogHtml
    };

    const _dialogRef = this.dialog.open(CommonDialogComponent, { data: dialogData });

  }

  public backOnClick(): void {
    this._router.navigate(['/summary']);
  }

  /**
   * Handles form submit for all payment methods.
   * Updated to include event param required for creating Apple Pay session object.
   */
  public onSubmit(event: Event): void {

    this.paymentForm.markAllAsTouched();
    this.paymentFormSubmitted = true;

    if(this.paymentForm.valid) {
      
      this.disableSubmit = true;
      this._spinnerService.show();

      // create the billing address.
      const usePolicyAddress = this.paymentForm.controls.usePolicyForBillingAddress.value;
      const billingAddress = new BillingAddress();

      if(usePolicyAddress === true) {
        billingAddress.line1 = this.certificate.clients[0].address.address1;
        billingAddress.line2 = this.certificate.clients[0].address.address2;
        billingAddress.line3 = this.certificate.clients[0].address.address3;
        billingAddress.city = this.certificate.clients[0].address.address4;
        billingAddress.postcode = this.certificate.clients[0].address.postCode;
        billingAddress.country = this.certificate.clients[0].address.address5;
        billingAddress.countryCode = this._paymentService.getIsoCountryCodeByResidencyId(this.certificate.residence);
      }

      if(usePolicyAddress === false) {
        billingAddress.line1 = this.paymentForm.controls.address.controls.address1.value;
        billingAddress.line2 = this.paymentForm.controls.address.controls.address2.value;
        billingAddress.line3 = this.paymentForm.controls.address.controls.address3.value;
        billingAddress.city = this.paymentForm.controls.address.controls.address4.value;
        billingAddress.postcode = this.paymentForm.controls.address.controls.postcode.value;
        billingAddress.country = this.paymentForm.controls.address.controls.address5.value;
        billingAddress.countryCode = this._paymentService.getIsoCountryCodeByCountryName(billingAddress.country, this.isoCountries);
      }

      if(this.paymentForm.controls.paymentMethod.value === SupportedPaymentMethod.ApplePay) {

        // TODO: Implement following.

        // CREATE SESSION.
        const session = this._paymentService.createApplePaySession(this.certificate.premium.totalGross);

        // VALIDATE MERCHANT
        // applePaySession.onvalidatemerchant = function( event ) { 
            
        //   var url = event.validationURL;
        //   var siteDomain = window.location.hostname;
      
        //   // The function createSession( url ) will make an ajax call to the site server, 
        //   // which will in turn call the Pay360 service /acceptor/rest/applepay/{instId}/validate/{applePayMerchantIdentifier}?validationURL={url}&siteDomain={siteDomain} 
        //   createSession( url, siteDomain ).done( ( response ) => { 
        //       session.completeMerchantValidation( response ); 
        //   } ); 
        // };

        // Temp implementation of above example. UNTESTED.
        this._window.ApplePaySession.onvalidatemerchant = (event) => {
          const url = event.validationURL;

          const applePayValidateMerchantRequest : ApplePayValidateMerchantRequest = {
            agentId: this._appConfigService.agentId,
            certificateId: this.certificate.certificateId,
            validationUrl: url
          }

          this._paymentService.validateApplePayMerchant(applePayValidateMerchantRequest)
            .subscribe(response => {
              session.completeMerchantValidation(response);
            });
        }
        

        // MAKE PAYMENT
        // session.onpaymentauthorized = function( event ) { 
            
        //   var token = event.payment.token;
      
        //   // The function authorise(token) will make an ajax call to the site server, which will in turn call 
        //   //the Pay360 service /acceptor/rest/transactions/{instId}/payment 
        //   authorise(token).done( ( response ) => { 
        //       // determine success by response from merchant server. In this example the response is outcome.status 
        //       //from the Pay360 api response returned to your server
      
        //       var success = "SUCCESS" === response;
      
        //       var authorizationResult = {
        //           status: ( success ? ApplePaySession.STATUS_SUCCESS : ApplePaySession.STATUS_FAILURE ),
        //           errors: []
        //       };
      
        //       session.completePayment( authorizationResult ); // hides the Apple Pay sheet
          
        //       if ( success ) { 
        //     showConfirmation( response );
        //       } else {
        //     showDecline( data );
        //       }
        //   } );
        // };

      }      

      if(this.paymentForm.controls.paymentMethod.value === SupportedPaymentMethod.PayPal) {

        const payPalPaymentRequest : PayPalPaymentRequest = {
          agentId: this._appConfigService.agentId,
          certificateId: this.certificate.certificateId,
          billingAddress: billingAddress
        };

        this._paymentService.makePayPalPayment(payPalPaymentRequest)
          .subscribe(response => {
            this.handlePayPalResponse(response);
          },
          err => {
            console.log(err);
            this._spinnerService.hide();
          });
      }

      if(this.paymentForm.controls.paymentMethod.value === SupportedPaymentMethod.Card) {

        const expiryDateParsed = this.parseExpiryDate(this.paymentForm.controls.card.controls.expiryMonth.value,
                                                      this.paymentForm.controls.card.controls.expiryYear.value);

        const cardPaymentRequest: CardPaymentRequest = {
          agentId: this._appConfigService.agentId,
          certificateId: this.certificate.certificateId,
          cardHolderName: this.paymentForm.controls.card.controls.cardHolderName.value,
          expiryDate: expiryDateParsed,
          cardType: this.paymentForm.controls.card.controls.cardType.value,
          pan: this.paymentForm.controls.card.controls.pan.value,
          issueNumber: this.paymentForm.controls.card.controls.issueNumber.value,
          cv2: this.paymentForm.controls.card.controls.cv2.value,
          startDate: this.paymentForm.controls.card.controls.startDate.value,
          billingAddress: billingAddress,
          noMail: this.isCyti ? !this.paymentForm.controls.marketingAndReqs.controls.optInMail.value : null
        };

        this._paymentService.makeCardPayment(cardPaymentRequest)
          .subscribe(response => {
            this.handleCardPaymentResponse(response);
          },
          err => {
            console.log(err);
            this._spinnerService.hide();
          });

      }

    }

  }
  
  /**
   * Payment response handling methods.
   */

  /**
   * Card and general handling methods.
   */
  private handleCannotPurchasePolicy(): void {
    this.showForm = false;
    this.showCannotPurchasePolicyError = true;
    this._spinnerService.hide();
  }

  private handleCardPaymentResponse(response: CardPaymentResponse): void {

    if(!response.canPurchasePolicy) {
      this.handleCannotPurchasePolicy();
      return;
    }
    
    if(response.magentaServiceError) {
      this.handleMagentaSericeError(response.magentaServiceError);
      return;
    }

    if(response.threeDSecureAuthRequired) {
      this.handleThreeDSecureRedirect(response);
      return;
    }

    if(response.authorised) {
      this.handlePaymentSuccess(response.liveCertificate);
      return;
    }

    if(response.authorised === false) {
      this.handleCardPaymentFail(response);
      return;
    }

  }

  private handleMagentaSericeError(serverErrorInfo: ServerErrorInfo): void {
    this.showForm = false;
    this.magentaServiceErrorDesc = serverErrorInfo.description;
    this.showMagentaServiceError = true;
    this._spinnerService.hide();
  }

  private handlePaymentSuccess(liveCertificate: Certificate): void {
    this._policyService.setCertificate(liveCertificate);
    this._spinnerService.hide();
    this._router.navigate(['/confirmation']);
  }

  private handleCardPaymentFail(response: CardPaymentResponse): void {
    this.disableSubmit = false;
    this._spinnerService.hide();
    this.openCommonDialog('CardPaymentFail', response.reasonCode);
  }

  /**
   * 3DS handling methods.
   */

  private handleThreeDSecureRedirect(response: CardPaymentResponse): void {

    // Construct transient data.
    const transientThreeDSecureData: TransientThreeDSecureData = {
      certificateId: this.certificate.certificateId,
      transactionId: response.transactionId,
      redirectUrl: response.clientRedirectUrl,
      paReq: response.pareq,
      termUrl: response.threeDSecureTermUrl,
      threeDSecureVersion: response.threeDSecureVersion,
      threeDSServerTransId: response.threeDSServerTransId,
      noMail: this.isCyti ? !this.paymentForm.controls.marketingAndReqs.controls.optInMail.value : null
    };

    // Set data in payment service and route.
    this._paymentService.transientThreeDSecureData = transientThreeDSecureData;
    this._spinnerService.hide();
    this._router.navigate(['/payment-auth']);

  }

  private handleThreeDSecurePaymentFailed(): void {
    this.openCommonDialog('ThreeDSecurePaymentFail');
  }

  private handleThreeDSecurePaymentSuccessWithPolicyGenError(): void {
    this.showForm = false;
    this.magentaServiceErrorDesc = 'An error occured while generating your policy after 3D Secure authorisation. Please call the number at the top of the screen.';
    this.showMagentaServiceError = true;
  }

  private handleThreeDSecurePaymentSuccess(): void {

    this.showForm = false;
    this._spinnerService.show();

    const byRecentlyPurchasedRequest: ByRecentlyPurchasedRequest = {
      agentId: this._appConfigService.agentId,
      certificateId: this.certificate.certificateId,
      email: this.certificate.clients[0].email
    };

    // Get new live policy.
    this._policyService.getPolicyByRecentlyPurchased(byRecentlyPurchasedRequest)
      .subscribe(response => {
        if(response.policyFound && response.isWithinRetrievalWindow) {
          this._policyService.setCertificate(response.certificate);
          this._spinnerService.hide();
          this._router.navigate(['/confirmation']);
        }

        if(!response.policyFound || !response.isWithinRetrievalWindow) {
          this.handlePolicyRecentlyPurchasedFail();
        }
      },
      err => {
        console.log(err);
        this.handlePolicyRecentlyPurchasedFail();
      });

  }

  private handlePolicyRecentlyPurchasedFail(): void {
    this.showForm = false;
    this.magentaServiceErrorDesc = 'Payment has been taken and your policy issued. However, a problem occurred while retrieving the live version. Please call the number at the top of this page.';
    this.showMagentaServiceError = true;
    this._spinnerService.hide();
  }

  /**
   * PayPal handling methods.
   */

  private handlePayPalResponse(response: PayPalPaymentResponse): void {

    if(!response.canPurchasePolicy) {
      this.handleCannotPurchasePolicy();
      return;
    }

    if(response.isPaymentSuspendedByPayPal) {
      this.handlePayPalRedirect(response.transactionId, response.clientRedirectUrl);
      return;
    }

    if(response.isPaymentSuspendedByPayPal === false) {
      this.handlePayPalPaymentFail();
      return;
    }

  }

  private handlePayPalPaymentFail(): void {
    this.disableSubmit = false;
    this._spinnerService.hide();
    this.openCommonDialog('PayPalPaymentFail');
  }

  private handlePayPalRedirect(transactionId: string, clientRedirectUrl: string): void {
    if(this.isCyti) {
      this._paymentService.setCytiNoMailVal(!this.paymentForm.controls.marketingAndReqs.controls.optInMail.value);
    }

    this._paymentService.setPayPalResumeTransId(transactionId);
    window.location.href = clientRedirectUrl;
  }

  private handlePayPalCancel(): void {
    this.openCommonDialog('PayPalPaymentCancel');
  }
  
  private handlePayPalResumeResponse(response: PayPalPaymentResumeResponse): void {

    if(response.magentaServiceError) {
      this.handleMagentaSericeError(response.magentaServiceError);
      return;
    }

    if(response.authorised) {
      this.handlePaymentSuccess(response.liveCertificate);
      return;
    }

    if(response.authorised === false) {
      this.handlePayPalResumeFail();
      return;
    }

  }

  private handlePayPalResumeFail(): void {
    this.showForm = true;
    this._spinnerService.hide();
    this.openCommonDialog('PayPalResumeFail');
  }

  /**
   * Apple Pay handling methods.
   * IN DEVELOPMENT
   */
  
  private handleApplePayResponse(response: ApplePayPaymentResponse): void {

  }

}
