import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs';
import { TaskProgressLogService } from '../services/task-progress-log.service';
import { LogEntryItem } from '../models/task-progress-log/log-entry-item';

@Component({
  selector: 'app-task-progress-log',
  templateUrl: './task-progress-log.component.html',
  styleUrls: ['./task-progress-log.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskProgressLogComponent implements OnInit {

  public logEntryItems$: Observable<LogEntryItem[]>;

  constructor(private _taskProgressLogService: TaskProgressLogService) {

    this.logEntryItems$ = this._taskProgressLogService.logEntryItems;
    
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {

  }

}
