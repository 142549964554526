import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  constructor() { }

  logError(message: string, stack: string): void {
    // TODO: Log to API method to be stored/emailed.
    // Could use service such as Bugsnag, Sentry (probably most suitable https://sentry.io/for/angular/), TrackJs, or Rollbar in production.
    // The console.log is only for testing this example.
    console.log(`LoggingService: ${message}`);
  }
}
