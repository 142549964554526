import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { LoggingService } from './services/logging.service';
import { ErrorService } from './services/error.service';
import { NotificationService } from './services/notification.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(private _injector: Injector) { }
  
  handleError(error: Error | HttpErrorResponse) {
    const errorService = this._injector.get(ErrorService);
    const logger = this._injector.get(LoggingService);
    const notifier = this._injector.get(NotificationService);

    let message: string;
    let stackTrace: string;

    if (error instanceof HttpErrorResponse) {
      // Server error
      message = errorService.getServerMessage(error);
      //stackTrace = errorService.getServerErrorStackTrace(error);
      notifier.showError(message);
    } else {
      // Client error
      message = errorService.getClientMessage(error);
      notifier.showError(message);
    }

    // Always log errors
    logger.logError(message, stackTrace);
    console.error(error);
  }
}