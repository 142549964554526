export class BillingAddress {

  public line1: string;

  public line2: string;

  public line3: string;

  public line4: string;

  public city: string;

  public region: string;

  public postcode: string;

  public country: string;

  public countryCode: string;

}