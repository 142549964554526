<div class="grid-main-content">
  <main class="item-main">
  
    <div class="common-intro">
      <h1 class="common-intro__hdr">Page Not Found</h1>
    </div>
    <div class="common-char-width-limit">
      <p>The page you are looking for could not be found. Click the 'New Quote' button to begin entering information for a new quote.</p>
    </div>
    
    <mat-divider class="common-margin-md-y"></mat-divider>
    
    <div class="primary-actions-container common-margin-lg-y">
      <div class="primary-actions">
        <button [routerLink]="['/']" tabindex="0" class="primary-actions__btn" type="button" mat-raised-button color="primary" aria-label="New Quote">New Quote</button>
      </div>
    </div>

  </main>
</div>