import { Component, NgZone, OnInit } from '@angular/core';
import { AppConfigService } from './services/app-config.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, tap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  private _defaultAppTitle: string;

  public constructor(private _ngZone: NgZone,
                     private _appConfigService: AppConfigService,
                     private _router: Router,
                     private _activatedRoute: ActivatedRoute,
                     private _titleService: Title,
                     private _window: Window) {

    (<any>this._window).threeDsRouteBackToPayment = this.threeDsRouteBackToPayment.bind(this);
    this._defaultAppTitle = this._appConfigService.defaultAppTitle;

  }

  /**
   * Function used to route iFrame 3DS traffic back to payment component.
   * Required to avoid reloading NG app.
   */
  public threeDsRouteBackToPayment(threeDs: number, transactionId: string, outcome: number): void {
    this._ngZone.run(() => {
      this._router.navigate(['/payment'], {
        queryParams: {
          threeds: threeDs,
          tid: transactionId,
          outcome: outcome
        }
      });
    });
  }

  ngOnInit(): void {

    this._router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      tap((event: NavigationEnd) => {
        let child = this._activatedRoute.firstChild;
        let pageTitle = this._defaultAppTitle;

        while(child.firstChild) {
          child = child.firstChild;
        }

        if(child.snapshot.data['title']) {
          pageTitle = child.snapshot.data['title'];
        }

        this._titleService.setTitle(pageTitle);
        this._window.dataLayer.push({ event: 'pageView', page: event.urlAfterRedirects, title: pageTitle });
      })
    ).subscribe();

  }

}