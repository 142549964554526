import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LogEntryItem } from '../models/task-progress-log/log-entry-item';

@Injectable({
  providedIn: 'root'
})
export class TaskProgressLogService {

  private _logEntryItems: LogEntryItem[];
  private _logEntryItemsSource: BehaviorSubject<LogEntryItem[]>;

  public readonly logEntryItems: Observable<LogEntryItem[]>;

  constructor() {

    this._logEntryItems = [];

    this._logEntryItemsSource = new BehaviorSubject<LogEntryItem[]>(null);
    this.logEntryItems = this._logEntryItemsSource.asObservable();

  }
  
  /**
   * Adds a log item entry.
   */
  public addLogEntry(logEntryItem: LogEntryItem): void {
    this._logEntryItems.push(logEntryItem);
    this._logEntryItemsSource.next(this._logEntryItems);
  }

  /**
   * Clears all log entries.
   */
  public clearAll(): void {
    this._logEntryItems = [];
    this._logEntryItemsSource.next(null);
  }
}
