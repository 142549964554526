import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { AppConfigService } from '../services/app-config.service';
import { CampaignService } from '../services/campaign.service';
import { ImageInfo } from '../models/image-info';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-campaign-discount-banner',
  templateUrl: './campaign-discount-banner.component.html',
  styleUrls: ['./campaign-discount-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CampaignDiscountBannerComponent implements OnInit, OnDestroy {

  public campaignDiscountBannerViewModel$: Observable<{ discountBannerImageInfo: ImageInfo,
                                                        discountBannerImagePath: string }>;

  constructor(private _appConfigService: AppConfigService,
              private _campaignService: CampaignService) {

  }

  ngOnInit(): void {

    this.campaignDiscountBannerViewModel$ = this._campaignService.activeCampaign
      .pipe(
        filter(camp => camp?.discountBannerImage !== null),
        map(camp => {
          return {
            discountBannerImageInfo: camp.discountBannerImage,
            discountBannerImagePath: `/assets/images/agents/${this._appConfigService.agentId}/${camp.discountBannerImage.fileName}`
          };
        })
      );

  }

  ngOnDestroy(): void {
    
  }

}