import { ImageInfo } from './image-info';

export class Campaign {

  public campaignId: number;

  public campaignName: string;

  public label: string;

  public telephoneNumber: string;

  public logoImage: ImageInfo;

  public discountBannerImage: ImageInfo;

}