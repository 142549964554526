<div class="common-container">
  <div class="steps-container">
    <ul *ngIf="progressIndicatorViewModel$ | async as vm" class="step-list">
      <ng-container *ngFor="let item of vm.stepItems; index as i">
        <li *ngIf="item.isHidden === false"
            class="step-list__item"
            [ngClass]="{ 'step-list__item--active': item.isActive, 'step-list__item--done': i < vm.activeStepItemIndex }">
          <div class="item-icon">
            <mat-icon *ngIf="item.isActive && i !== vm.stepItems.length - 1" aria-hidden="true" aria-label="Editing">edit</mat-icon>
            <mat-icon *ngIf="i < vm.activeStepItemIndex && !item.isActive && i !== vm.stepItems.length - 1" aria-hidden="true" aria-label="Check">check_circle</mat-icon>
            <mat-icon *ngIf="i > vm.activeStepItemIndex && !item.isActive && i !== vm.stepItems.length - 1" aria-hidden="true" aria-label="Pending">pending</mat-icon>
            <mat-icon *ngIf="i === vm.stepItems.length - 1" aria-hidden="true" aria-label="Final step">verified</mat-icon>
          </div>
          <div class="item-name">{{ item.stepItemName }}</div>
        </li> 
      </ng-container>
    </ul>
  </div>
</div>