export enum CertificateStatus {

  Superseded = 0,
  Void = 1,
  Cancelled = 2,
  IssuedToAgent = 3,
  AwaitingInvoice = 4,
  IncompleteQuote = 5,
  Live = 6,
  CompletedQuote = 7,
  IncompleteEndorsement = 8

}