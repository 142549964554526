<ng-container *ngIf="sidebarViewModel$ | async as vm">
  <div *ngIf="vm.certificate" class="sb-sum-wrapper mat-elevation-z2">
    <div class="sb-sum-item-row">
      <div class="sb-sum-item">
        <h3 class="sb-sum-item__hdr">Quote Summary</h3>
      </div>
    </div>
    <div class="sb-sum-item-row">
      <div *ngIf="vm.certificate.quoteReference && vm.certificate.quoteReference !== 0" class="sb-sum-item">
        <span class="sb-sum-item__label">Quote Number:</span> {{ vm.certificate.quoteReference }}
        <!--
          Use for help dialog open.
          <button class="sb-sum-item__help" tabindex="0" type="button" mat-icon-button aria-label="Help"><mat-icon>help</mat-icon></button>
        -->
      </div>
      <div class="sb-sum-item">
        <span class="sb-sum-item__label">Residency:</span> {{ vm.displayData.residencyName }}
      </div>
    </div>
    <div class="sb-sum-item-row">
      <div class="sb-sum-item">
        <span class="sb-sum-item__label">Policy Type:</span> {{ vm.displayData.policyType }}
      </div>
      <div class="sb-sum-item">
        <ng-container *ngIf="vm.countryNames; else destinationNameBlock">
          <span class="sb-sum-item__label">Destination Countries:</span> {{ vm.countryNames }}
        </ng-container>
        <ng-template #destinationNameBlock>
          <span class="sb-sum-item__label">Destination:</span> {{ vm.certificate.destinationName }}
        </ng-template>
      </div>
    </div>
    <div class="sb-sum-item-row">
      <div class="sb-sum-item">
        <span class="sb-sum-item__label">Start Date:</span> {{ vm.certificate.startDate | date: 'dd/MM/yyyy' }}
      </div>
      <div class="sb-sum-item">
        <span class="sb-sum-item__label">End Date:</span> {{ vm.certificate.endDate | date: 'dd/MM/yyyy' }}
      </div>
    </div>

    <ng-container *ngIf="vm.displayFinancials">
      <div class="sb-sum-item-row">
        <div class="sb-sum-item">
          <span class="sb-sum-item__label">Policy:</span> {{ vm.certificate?.scheme?.friendlyName }}
        </div>
        <!--
          <div class="sb-sum-item">
            <span class="sb-sum-item__label">Premium:</span> {{ vm.certificate.premium.totalGross | currency }}
          </div>
        -->
      </div>
      <div *ngIf="vm.displayData.hasUserSelectedPolicyOptions" class="sb-sum-item-row">
        <div class="sb-sum-item">
          <span class="sb-sum-item__label">Policy Options:</span>
          <ul class="sb-sum-item__list">
            <ng-container *ngFor="let policyOption of vm.certificate.policyOptions">
              <li *ngIf="!policyOption.hideOnDocuments" class="sb-sum-item__list-item sb-sum-item__list-item--option">{{ policyOption.name }}</li>
            </ng-container>
          </ul>
        </div>
      </div>
    </ng-container>

    <div class="sb-sum-item-row">
      <div class="sb-sum-item">
        <span class="sb-sum-item__label">Travellers:</span>
        <ul class="sb-sum-item__list">
          <li *ngFor="let client of vm.certificate.clients" class="sb-sum-item__list-item sb-sum-item__list-item--trav">{{ client.title }} {{ client.firstName }} {{ client.lastName }} <br /> ({{ client.dateOfBirth | date: 'dd/MM/yyyy' }})
            
            <ng-container *ngIf="vm.displayMedicalScreenings && client?.screening?.conditions?.length > 0">
              <span class="sb-sum-item__sub-label">Medical conditions:</span>
              <ul class="sb-sum-item__sub-list">
                <li *ngFor="let condition of client.screening.conditions">{{ condition.conditionName }} - {{ condition.excluded === true ? 'Excluded' : 'Covered' }}</li>
              </ul>
            </ng-container>
            
            <ng-container *ngIf="vm.displayFinancials && client?.options?.length > 0">
              <span class="sb-sum-item__sub-label">Person options:</span>
              <ul class="sb-sum-item__sub-list">
                <li *ngFor="let personOption of client.options">{{ personOption.name }}</li>
              </ul>
            </ng-container>

          </li>
        </ul>
      </div>
    </div>

    <ng-container *ngIf="vm.displayFinancials">
      <div class="sb-sum-item-row">
        <div class="sb-sum-item">
          <span class="sb-sum-item__label">Total Amount:</span> {{ vm.certificate?.overallGross | currency }}
        </div>
      </div>
    </ng-container>

    <div *ngIf="vm.displayData.showActions" class="sb-sum-item-row">
      <div class="sb-sum-item sb-sum-item--actions">
        <button *ngIf="vm.displayData.showEditButton" tabindex="0" type="button" mat-raised-button aria-label="Edit" (click)="editOnClick()">Edit</button>
        <button tabindex="0" type="button" mat-raised-button aria-label="Restart Quote" (click)="restartOnClick()">Restart Quote</button>
      </div>
    </div>
  </div>
</ng-container>