<div class="page">

  <div class="content">

    <app-header></app-header>

    <app-progress-indicator></app-progress-indicator>
    
    <div class="common-container common-container--main">

      <router-outlet></router-outlet>

    </div>

  </div>

  <app-footer></app-footer>

</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.2)" size="medium" color="#fff" type="ball-clip-rotate"></ngx-spinner>