import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { AppConfigService } from '../services/app-config.service';
import { CampaignService } from '../services/campaign.service';
import { Campaign } from '../models/campaign';
import { Observable, combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit, OnDestroy {

  public campaign$: Observable<Campaign>;
  public displayData$: Observable<{ agentName: string,
                                    brandColourPrimary: string,
                                    footerContent: string,
                                    generalEmail: string,
                                    customerSiteUrl: string,
                                    customerSiteUrlDisplay: string }>;
  public footerViewModel$: Observable<{ campaign: Campaign, displayData: unknown }>;

  constructor(private _appConfigService: AppConfigService,
              private _campaignService: CampaignService) {
   
  }

  ngOnInit(): void {
    this.campaign$ = this._campaignService.activeCampaign;

    this.displayData$ = of({ 
      agentName: this._appConfigService.agent.name,
      brandColourPrimary: this._appConfigService.agent.brandColourPrimary,
      footerContent: this._appConfigService.getFooterContentSnippet().value,
      generalEmail: this._appConfigService.agent.generalEmail,
      customerSiteUrl: this._appConfigService.agent.customerSiteUrl,
      customerSiteUrlDisplay: this._appConfigService.agent.customerSiteUrl.replace(/(^\w+:|^)\/\//, '').replace(/\/$/, '')
    });

    this.footerViewModel$ = combineLatest([this.campaign$, this.displayData$])
      .pipe(
        map(([campaign, displayData]) => ({ campaign, displayData }))
      );
  }

  ngOnDestroy(): void {
    
  }

}