import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { AppConfigService } from '../services/app-config.service';
import { CampaignService } from '../services/campaign.service';
import { Campaign } from '../models/campaign';
import { Observable, combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit, OnDestroy {

  public campaign: Campaign;
  public agentName: string;
  public logoImgUrl: string;
  public brandColourPrimary: string;
  public openingHours: string;

  public campaign$: Observable<Campaign>;
  public displayData$: Observable<{ agentName: string,
                                    logoImgUrl: string,
                                    brandColourPrimary: string,
                                    openingHours: string }>;
  public headerViewModel$: Observable<{ campaign: Campaign, displayData: unknown }>;

  constructor(private _appConfigService: AppConfigService,
              private _campaignService: CampaignService) {
      
  }

  ngOnInit(): void {
    this.campaign$ = this._campaignService.activeCampaign;

    this.displayData$ = of({ 
      agentName: this._appConfigService.agent.name,
      logoImgUrl: `assets/images/agents/${this._appConfigService.agentId}/logo.svg`,
      brandColourPrimary: this._appConfigService.agent.brandColourPrimary,
      openingHours: this._appConfigService.getAgentContentSnippetById('openingHours').value
    });

    this.headerViewModel$ = combineLatest([this.campaign$, this.displayData$])
      .pipe(
        map(([campaign, displayData]) => ({ campaign, displayData }))
      );
  }

  ngOnDestroy(): void {
    
  }

}