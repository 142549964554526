import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-certificate-loaded',
  templateUrl: './no-certificate-loaded.component.html',
  styleUrls: ['./no-certificate-loaded.component.scss']
})
export class NoCertificateLoadedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
