import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, from, defer } from 'rxjs';
import { shareReplay, take } from 'rxjs/operators';
import { AppConfigService } from './app-config.service';
import { IsoCountry } from '../models/iso-country';
import { TransientThreeDSecureData } from '../models/transient-three-d-secure-data';
import { CardPaymentRequest } from '../models/dto/payment/card-payment-request';
import { CardPaymentResponse } from '../models/dto/payment/card-payment-response';
import { PayPalPaymentRequest } from '../models/dto/payment/paypal-payment-request';
import { PayPalPaymentResponse } from '../models/dto/payment/paypal-payment-response';
import { PayPalPaymentResumeRequest } from '../models/dto/payment/paypal-payment-resume-request';
import { PayPalPaymentResumeResponse } from '../models/dto/payment/paypal-payment-resume-response';
import { ApplePayPaymentRequest } from '../models/dto/payment/apple-pay-payment-request';
import { ApplePayPaymentResponse } from '../models/dto/payment/apple-pay-payment-response';
import { ApplePayValidateMerchantRequest } from '../models/dto/payment/apple-pay-validate-merchant-request';
import { ApplePayValidateMerchantResponse } from '../models/dto/payment/apple-pay-validate-merchant-response';


declare global {
  interface Window { ApplePaySession: any }
}

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  private readonly _cytiNoMailValSessionKey = 'payment_svc_cyti_no_mail_val';
  private readonly _cardResumeTransIdSessionKey = 'payment_svc_card_res_trans_id';
  private readonly _payPalResumeTransIdSessionKey = 'payment_svc_paypal_res_trans_id';

  private _transientThreeDSecureData: TransientThreeDSecureData;

  constructor(private _appConfigService: AppConfigService,
              private _http: HttpClient,
              private _window: Window) {
    this._transientThreeDSecureData = null;
  }

  /**
   * Takes the given reasonCode and returns an appropriate user message.
   * Only certain messages are suitable for display so limited number of
   * codes are recognised.
   * Returns the message or when code not matched an empty string.
   */
  public getReasonCodeMessage(reasonCode: string): string {
    switch(reasonCode) {
      case 'V302': {
        return 'Invalid card number. Please check you have entered the long number on the front of the card correctly.';
      }
      case 'A117': {
        return 'The attempted card brand is not supported. Please use an alternative card or payment type.';
      }
      default: {
        return '';
      }
    }
  }

  /**
   * Gets ISO country data.
   */
  public getIsoCountries(): Observable<IsoCountry[]> {
    const url = './assets/data/iso-countries.json';
    return this._http.get<IsoCountry[]>(url)
             .pipe(
               shareReplay(1)
             );
  }

  /**
   * Takes the country name and list of ISO countries and returns the 3 char ISO code.
   */
  public getIsoCountryCodeByCountryName(name: string, isoCountries: IsoCountry[]): string {
    const isoCountry = isoCountries.find(({ countryName }) => countryName === name);

    if(isoCountry === undefined) throw new Error(`No isoCountry found with countryName = ${name}.`);

    return isoCountry.isoCode;
  }

  /**
   * Returns the ISO code for the given residency.
   */
  public getIsoCountryCodeByResidencyId(residencyId: number): string {
    return residencyId === 3 ? "IMN" : "GBR";
  }

  /**
   * Gets/sets the 3DS data required to initialise a 3DS auth.
   */
  public get transientThreeDSecureData() : TransientThreeDSecureData {
    return this._transientThreeDSecureData;
  }

  public set transientThreeDSecureData(value : TransientThreeDSecureData) {
    this._transientThreeDSecureData = value;
  }
  
  /**
   * Sets session var used to flag CYTI noMail preference for when
   * transactions are suspended and value needed for resume.
   */
  public setCytiNoMailVal(noMail: boolean): void {
    sessionStorage.setItem(this._cytiNoMailValSessionKey, noMail.toString());
  }

  /**
   * Gets value stored to session indicating CYTI noMail preference.
   * Used when transactions must be resumed for 3DS and PayPal.
   * If no value found in session, returns true (opting out preference).
   */
  public getCytiNoMailVal(): boolean {
    if(sessionStorage.getItem(this._cytiNoMailValSessionKey) && sessionStorage.getItem(this._cytiNoMailValSessionKey) === 'false') {
      return false;
    }

    return true;
  }

  /**
   * Sets session var used to record card payment transactionId suspended for 3DS.
   */
  public setCardResumeTransId(transactionId: string): void {
    sessionStorage.setItem(this._cardResumeTransIdSessionKey, transactionId);
  }

  /**
   * Gets the previously set card resume transactionId.
   */
  public getCardResumeTransId(): string {
    if(sessionStorage.getItem(this._cardResumeTransIdSessionKey) === null) {
      throw new Error('No resume card transaction ID found in session.');
    }

    return sessionStorage.getItem(this._cardResumeTransIdSessionKey);
  }

  /**
   * Sets session var used to record PayPal payment transactionId suspended for 3DS.
   */
  public setPayPalResumeTransId(transactionId: string): void {
    sessionStorage.setItem(this._payPalResumeTransIdSessionKey, transactionId);
  }

  /**
   * Gets the previously set PayPal resume transactionId.
   */
  public getPayPalResumeTransId(): string {
    if(sessionStorage.getItem(this._payPalResumeTransIdSessionKey) === null) {
      throw new Error('No resume PayPal transaction ID found in session.');
    }

    return sessionStorage.getItem(this._payPalResumeTransIdSessionKey);
  }

  /**
   * Sends a card payment.
   */
  public makeCardPayment(cardPaymentRequest: CardPaymentRequest): Observable<CardPaymentResponse> {
    const url = `${this._appConfigService.quoteApiBaseUrl}/Payment/MakeCardPayment`;
    return this._http.post<CardPaymentResponse>(url, cardPaymentRequest);
  }

  /**
   * Sends a PayPal payment.
   */
  public makePayPalPayment(payPalPaymentRequest: PayPalPaymentRequest): Observable<PayPalPaymentResponse> {
    const url = `${this._appConfigService.quoteApiBaseUrl}/Payment/MakePayPalPayment`;
    return this._http.post<PayPalPaymentResponse>(url, payPalPaymentRequest);
  }

  /**
   * Resumes a suspended PayPal payment.
   */
  public resumePayPalPayment(payPalPaymentResumeRequest: PayPalPaymentResumeRequest): Observable<PayPalPaymentResumeResponse> {
    const url = `${this._appConfigService.quoteApiBaseUrl}/Payment/ResumePayPalPayment`;
    return this._http.post<PayPalPaymentResumeResponse>(url, payPalPaymentResumeRequest);
  }

  /**
   * Apple Pay related methods.
   */

  /**
   * Returns a value indicating whether Apple Pay can be used as a payment method.
   */
  public isApplePayAvailable(): Observable<boolean> {
    if(this._window.ApplePaySession) {
      const merchantIdentifier = this._window.location.hostname;
      
      return defer(() => from(this._window.ApplePaySession.canMakePaymentsWithActiveCard(merchantIdentifier) as Promise<boolean>))
        .pipe(
          take(1)
        );
    }

    return from([false]);
  }

  /**
   * Creates a new Apple Pay session.
   */
  public createApplePaySession(amount: number): any {
    const request = {
      countryCode: 'GB',
      currencyCode: 'GBP',
      supportedNetworks: ['visa', 'masterCard'],
      merchantCapabilities: ['supports3DS'],
      total: {
        label: this._appConfigService.agent.name,
        amount: amount.toString()
      }
    }

    return new this._window.ApplePaySession(3, request);
  }

  /**
   * Apple Pay merchant validation request.
   */
  public validateApplePayMerchant(applePayValidateMerchantRequest: ApplePayValidateMerchantRequest): Observable<ApplePayValidateMerchantResponse> {
    const url = `${this._appConfigService.quoteApiBaseUrl}/Payment/ValidateApplePayMerchant`;
    return this._http.post<ApplePayValidateMerchantResponse>(url, applePayValidateMerchantRequest);
  }  

  /**
   * Sends a Apple Pay payment.
   */
  public makeApplePayPayment(applePayPaymentRequest: ApplePayPaymentRequest): Observable<ApplePayPaymentResponse> {
    const url = `${this._appConfigService.quoteApiBaseUrl}/Payment/MakeApplePayPayment`;
    return this._http.post<ApplePayPaymentResponse>(url, applePayPaymentRequest);
  }

}
