import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProgressStepItem } from '../models/progress-indicator/progress-step-item';

@Injectable({
  providedIn: 'root'
})
export class ProgressIndicatorService {

  private readonly _stepItems: ProgressStepItem[];
  private _stepItemsSource: BehaviorSubject<ProgressStepItem[]>;

  public readonly progressStepItems: Observable<ProgressStepItem[]>;

  constructor() {
  
    this._stepItems = this.initStepItems();

    this._stepItemsSource = new BehaviorSubject<ProgressStepItem[]>(this._stepItems);
    this.progressStepItems = this._stepItemsSource.asObservable();

  }

  private initStepItems(): ProgressStepItem[] {
    
    const stepItems = [
      {
        stepItemName: 'Travel Details',
        isActive: false,
        isHidden: false
      },
      {
        stepItemName: 'Medical',
        isActive: false,
        isHidden: false
      },
      {
        stepItemName: 'Quote and Options',
        isActive: false,
        isHidden: false
      },
      {
        stepItemName: 'Personal Details',
        isActive: false,
        isHidden: false
      },
      {
        stepItemName: 'Summary',
        isActive: false,
        isHidden: false
      },
      {
        stepItemName: 'Payment',
        isActive: false,
        isHidden: false
      }
    ];

    return stepItems;

  }
  
  /**
   * Sets the active step item.
   */
  public setActiveStep(stepIndex: number): void {

    if(stepIndex >= this._stepItems.length) throw new Error('stepIndex out of range.');

    this._stepItems.forEach((stepItem, index) => {
      stepItem.isActive = stepIndex === index;
    });

    this._stepItemsSource.next(this._stepItems);
    
  }

  /**
   * Takes an array of indexes and sets all matching steps as hidden.
   */
  public hideSteps(indexesToHide: number[]): void {

    this._stepItems.forEach((stepItem, index) => {
      stepItem.isHidden = indexesToHide.includes(index);
    });

    this._stepItemsSource.next(this._stepItems);

  }

}
