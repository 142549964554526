import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigService } from './app-config.service';
import { PostcodeSearchAddress } from '../models/postcode-search/postcode-search-address';
import { GetByIdRequest } from '../models/dto/postcode-search/get-by-id-request';
import { GetByPostcodeRequest } from '../models/dto/postcode-search/get-by-postcode-request';

@Injectable({
  providedIn: 'root'
})
export class PostcodeSearchService {

  constructor(private _appConfigService: AppConfigService,
              private _http: HttpClient) {
                
  }

  /**
   * Gets address by supplied ID.
   */
  public getById(getByIdRequest: GetByIdRequest): Observable<PostcodeSearchAddress> {
    const url = `${this._appConfigService.quoteApiBaseUrl}/PostcodeSearch/GetById`;
    return this._http.post<PostcodeSearchAddress>(url, getByIdRequest);
  }

  /**
   * Gets addresses with matching postcode.
   */
  public getByPostcode(getByPostcodeRequest: GetByPostcodeRequest): Observable<PostcodeSearchAddress[]> {
    const url = `${this._appConfigService.quoteApiBaseUrl}/PostcodeSearch/GetByPostcode`;
    return this._http.post<PostcodeSearchAddress[]>(url, getByPostcodeRequest);
  }

}
