import { Component, OnInit, OnDestroy, Input, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AppConfigService } from '../services/app-config.service';
import { PolicyService } from '../services/policy.service';
import { Certificate } from '../models/magenta/certificate';
import { PolicyTypes } from '../models/magenta/policy-types';
import { CertificateStatus } from '../models/magenta/certificate-status';
import { Observable, BehaviorSubject, combineLatest, of } from 'rxjs';
import { map, switchMap, filter } from 'rxjs/operators';
import { Country } from '../models/magenta/country';

@Component({
  selector: 'app-sidebar-summary',
  templateUrl: './sidebar-summary.component.html',
  styleUrls: ['./sidebar-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SidebarSummaryComponent implements OnInit, OnDestroy {

  private _displayFinancialsSource = new BehaviorSubject<boolean>(false);
  private _displayMedicalScreeningsSource = new BehaviorSubject<boolean>(false);
  public displayFinancials$ = this._displayFinancialsSource.asObservable();
  public displayMedicalScreenings$ = this._displayMedicalScreeningsSource.asObservable();
  public certificate$: Observable<Certificate>;
  public displayData$: Observable<{ policyType: string,
                                    residencyName: string,
                                    hasUserSelectedPolicyOptions: boolean,
                                    showActions: boolean,
                                    showEditButton: boolean }>;
  public sidebarViewModel$: Observable<{ certificate: Certificate,
                                         displayData: unknown,
                                         displayFinancials: boolean,
                                         displayMedicalScreenings: boolean }>;
  public countryNames$: Observable<string | null>;

  constructor(public dialog: MatDialog,
              private _appConfigService: AppConfigService,
              private _router: Router,
              private _policyService: PolicyService) {
    
  }

  ngOnInit(): void {

    this.certificate$ = this._policyService.certificate;

    this.countryNames$ = this.certificate$
      .pipe(
        filter(cert => cert !== null),
        switchMap(cert => {
          if(!cert.countryIds) return of(new Array<Country>());

          if(cert.scheme) {
            const allCountries = this._policyService.getAllCountriesFromDestinations(cert.scheme.destinations);
            const filteredCountries = this._policyService.getCountriesFilteredByIds(allCountries, cert.countryIds);
            return of(filteredCountries);
          }

          return this._policyService.getCountriesBySchemeGroupFiltered(cert.schemeGroup, cert.countryIds);
        }),
        map(countries => {
          if(countries.length === 0) return null;
          return countries.map(country => country.name).join(', ');
        })
      );

    this.displayData$ = this.certificate$
      .pipe(
        filter(cert => cert !== null),
        map(cert => {
          const policyType = cert.policyType === PolicyTypes.Single ? 'Single Trip' : 'Annual Multi Trip';
          const residencyName = this._appConfigService.getResidencyById(cert.residence).name;
          const hasUserSelectedPolicyOptions = this._policyService.hasUserSelectedPolicyOptions(cert);
          const showActions = !this._policyService.isMagentaSourceAggregator(cert.source);
          const showEditButton = cert.status === CertificateStatus.IncompleteQuote;

          return {
            policyType: policyType,
            residencyName: residencyName,
            hasUserSelectedPolicyOptions: hasUserSelectedPolicyOptions,
            showActions: showActions,
            showEditButton: showEditButton
          };
        })
      );

    this.sidebarViewModel$ = combineLatest([this.certificate$, this.countryNames$, this.displayData$, this.displayFinancials$, this.displayMedicalScreenings$])
      .pipe(
        map(([certificate, countryNames, displayData, displayFinancials, displayMedicalScreenings]) => ({ certificate, countryNames, displayData, displayFinancials, displayMedicalScreenings }))
      );
    
  }

  ngOnDestroy(): void {

  }

  @Input()
  set displayFinancials(displayFinacials: boolean) {
    this._displayFinancialsSource.next(displayFinacials);
  }

  @Input()
  set displayMedicalScreenings(displayMedicalScreenings: boolean) {
    this._displayMedicalScreeningsSource.next(displayMedicalScreenings);
  }

  public editOnClick(): void {
    this._router.navigate(['/']);
  }

  public restartOnClick(): void {
    this._policyService.clearCertificate();
    this._router.navigate(['/']);
  }

}