<ng-container [formGroup]="postcodeSearchForm">
  <div class="form-group-container">
    <div class="form-item">
      <div class="postcode-search-container">
        <div class="postcode-search-item postcode-search-input">
          <mat-form-field>
            <input id="address-search" tabindex="0" formControlName="postcode" type="text" matInput placeholder="Enter UK Postcode" />
            <mat-error *ngIf="postcodeSearchForm.get('postcode').errors?.required">Enter a UK postcode to find address.</mat-error>
            <mat-error *ngIf="postcodeSearchForm.get('postcode').errors?.minlength">Postcode should be at least 6 characters long.</mat-error>
            <mat-error *ngIf="postcodeSearchForm.get('postcode').errors?.maxlength">Postcode should be no more than 8 characters long.</mat-error>
          </mat-form-field>
        </div>
        <div class="postcode-search-item">
          <button tabindex="0"
                  type="button"
                  mat-raised-button
                  color="accent"
                  aria-label="Find"
                  [disabled]="findBtnDisabled"
                  (click)="postcodeSearchOnClick()">Find</button>
        </div>
        <div class="postcode-search-item postcode-search-spinner">
          <ngx-spinner name="search" [fullScreen]="false" bdColor="#fff" size="small" color="rgba(0, 0, 0, 0.5)" type="ball-clip-rotate"></ngx-spinner>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="postcodeSearchFormSubmitted === true && showNoAddressFoundMessage === true" class="no-results-container common-char-width-limit">
    <p><strong>No addresses found. Please check the postcode you entered and try again, or enter the address manually.</strong></p>
  </div>
  <div *ngIf="postcodeSearchFormSubmitted === true && postcodeSearchResults?.length > 0" class="address-results-list common-char-width-limit">
    <mat-selection-list tabindex="0"
                        [multiple]="false"
                        (selectionChange)="addressListOnChange($event)">
      <mat-list-option *ngFor="let postcodeSearchResult of postcodeSearchResults" [value]="postcodeSearchResult.postcodeLookupId">
        {{ postcodeSearchResult.addressAsOneLine }}
      </mat-list-option>
    </mat-selection-list>
  </div>
</ng-container>

<ng-container [formGroup]="addressForm">
  <div class="form-group-container">
    <div class="form-item">
      <mat-form-field>
        <input id="address1" tabindex="0" formControlName="address1" type="text" matInput placeholder="Address 1" />
        <mat-error *ngIf="addressForm.get('address1').errors">First line of the address required.</mat-error>
      </mat-form-field>
    </div>
    <div class="form-item">
      <mat-form-field>
        <input id="address2" tabindex="0" formControlName="address2" type="text" matInput placeholder="Address 2" />
      </mat-form-field>
    </div>
    <div class="form-item">
      <mat-form-field>
        <input id="address3" tabindex="0" formControlName="address3" type="text" matInput placeholder="Address 3" />
      </mat-form-field>
    </div>
    <div class="form-item">
      <mat-form-field>
        <input id="address4" tabindex="0" formControlName="address4" type="text" matInput placeholder="Town / City" />
        <mat-error *ngIf="addressForm.get('address4').errors">Please enter the town / city of the address.</mat-error>
      </mat-form-field>
    </div>
    <div class="form-item">
      <mat-form-field>
        <input id="postcode" tabindex="0" formControlName="postcode" type="text" matInput placeholder="Postcode" />
        <mat-error *ngIf="addressForm.get('postcode').errors">Please enter the postcode of the address.</mat-error>
      </mat-form-field>
    </div>
    <div *ngIf="isPaymentMode === true" class="form-item">
      <mat-form-field *ngIf="isoCountries; else countrySelectLoading">
        <mat-label>Country</mat-label>
        <mat-select id="address5" tabindex="0" formControlName="address5" placholder="Country">
          <mat-option *ngFor="let isoCountry of isoCountries" [value]="isoCountry.countryName">{{ isoCountry.countryName }}</mat-option>
        </mat-select>
      </mat-form-field>
      <ng-template #countrySelectLoading>
        <p>Loading country selection list...</p>
      </ng-template>
    </div>
  </div>
</ng-container>