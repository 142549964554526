import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { ProgressIndicatorService } from '../services/progress-indicator.service';
import { ProgressStepItem } from '../models/progress-indicator/progress-step-item';
import { Subscription, Observable, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-progress-indicator',
  templateUrl: './progress-indicator.component.html',
  styleUrls: ['./progress-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressIndicatorComponent implements OnInit, OnDestroy {

  public stepItems$: Observable<ProgressStepItem[]>;
  public activeStepItemIndex$: Observable<number>;
  public routerProgStep$: Observable<number>;
  public progressIndicatorViewModel$: Observable<{ stepItems: ProgressStepItem[], activeStepItemIndex: number }>;

  private _routerProgStepSub: Subscription;

  constructor(private _router: Router,
              private _activatedRoute: ActivatedRoute,
              private _progressIndicatorService: ProgressIndicatorService) {
        
  }

  ngOnInit(): void {

    this.stepItems$ = this._progressIndicatorService.progressStepItems;

    this.activeStepItemIndex$ = this.stepItems$
      .pipe(
        map(items => {
          return items.findIndex(item => item.isActive);
        })
      );

    this.routerProgStep$ = this._router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let child = this._activatedRoute.firstChild;

        while(child.firstChild) {
          child = child.firstChild;
        }

        const progStepIndex = Number(child.snapshot.data['progStepIndex']);

        if(!isNaN(progStepIndex)) {
          return progStepIndex;
        }
        
        return -1;
      })
    );

    this.progressIndicatorViewModel$ = combineLatest([this.stepItems$, this.activeStepItemIndex$])
      .pipe(
        map(([stepItems, activeStepItemIndex]) => ({ stepItems, activeStepItemIndex }))
      );

    this._routerProgStepSub = this.routerProgStep$
      .subscribe((activeStepIndex: number) => {
        this._progressIndicatorService.setActiveStep(activeStepIndex);
      });

  }

  ngOnDestroy(): void {
    this._routerProgStepSub.unsubscribe();
  }

}