import { GlobalErrorHandler } from './global-error-handler';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, LOCALE_ID, DEFAULT_CURRENCY_CODE, APP_INITIALIZER } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IS_ENV_PROD_MODE } from './models/environment-token';

import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en-GB';
import localeEnExtra from '@angular/common/locales/extra/en-GB';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button'; 
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRippleModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';

// Moment date config
import { MatMomentDateModule, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';

// Local date formats
import { DATE_FORMATS } from './models/date-formats-token';

import { HttpClient } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppConfigService } from './services/app-config.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ServerErrorInterceptor } from './server-error.interceptor';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxMaskModule } from 'ngx-mask';
import { CookieService } from 'ngx-cookie-service';

// Common shared components
import { NotFoundComponent } from './not-found/not-found.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ProgressIndicatorComponent } from './progress-indicator/progress-indicator.component';
import { CommonDialogComponent } from './common-dialog/common-dialog.component';

// Common shared modules
import { SidebarSummaryModule } from './sidebar-summary/sidebar-summary.module';
import { TaskProgressLogModule } from './task-progress-log/task-progress-log.module';
import { CampaignDiscountBannerModule } from './campaign-discount-banner/campaign-discount-banner.module';
import { CampaignLogoModule } from './campaign-logo/campaign-logo.module';
import { AddressEntryModule } from './address-entry/address-entry.module';

// Eager loaded components
import { PersonalComponent } from './personal/personal.component';
import { PaymentComponent } from './payment/payment.component';
import { PaymentAuthComponent } from './payment-auth/payment-auth.component';
import { QuoteCannotEditComponent } from './quote-cannot-edit/quote-cannot-edit.component';
import { NoCertificateLoadedComponent } from './no-certificate-loaded/no-certificate-loaded.component';

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    HeaderComponent,
    FooterComponent,
    ProgressIndicatorComponent,
    CommonDialogComponent,
    PersonalComponent,
    PaymentComponent,
    PaymentAuthComponent,
    QuoteCannotEditComponent,
    NoCertificateLoadedComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    NgxMaskModule.forRoot(),
    SidebarSummaryModule,
    TaskProgressLogModule,
    CampaignDiscountBannerModule,
    CampaignLogoModule,
    AddressEntryModule,
    MatSnackBarModule,
    MatDialogModule,
    MatDividerModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatBadgeModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatListModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatRippleModule,
    MatRadioModule,
    MatCheckboxModule
  ],
  providers: [
    { provide: IS_ENV_PROD_MODE, useValue: environment.production },
    { provide: LOCALE_ID, useValue: 'en-GB' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'GBP' },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { panelClass: 'mat-dialog-default', hasBackdrop: true } },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { strict: true, useUtc: true } },
    { provide: DateAdapter, useClass: MomentDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: {
        parse: {
          dateInput: ['L'],
        },
        display: {
          dateInput: 'L',
          monthYearLabel: 'MMMM Y',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        }
      }
    },
    {
      provide: APP_INITIALIZER,
        useFactory: appInit,
        multi: true,
        deps: [AppConfigService, HttpClient]
    },
    { provide: Window, useValue: window },
    { provide: DATE_FORMATS, useValue: {
        friendlyDisplayFormat: 'dddd Do MMMM YYYY',
        policyDisplayFormat: 'D MMMM YYYY',
        txRxFormat: 'YYYY-MM-DD',
        validationFormat: 'DD/MM/YYYY'
      }
    },
    CookieService,
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }

export function appInit(appConfigService: AppConfigService) {

  return () => appConfigService.load();

}

registerLocaleData(localeEn, 'en-GB', localeEnExtra);