import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PersonalComponent } from './personal/personal.component';
import { PaymentComponent } from './payment/payment.component';
import { PaymentAuthComponent } from './payment-auth/payment-auth.component';
import { QuoteCannotEditComponent } from './quote-cannot-edit/quote-cannot-edit.component';
import { NoCertificateLoadedComponent } from './no-certificate-loaded/no-certificate-loaded.component';
import { NotFoundComponent } from './not-found/not-found.component';


const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./quote-detail/quote-detail.module').then(m => m.QuoteDetailModule)
  },
  { 
    path: 'medical',
    pathMatch: 'full',
    loadChildren: () => import('./medical/medical.module').then(m => m.MedicalModule)
  },
  {
    path: 'vsk-screening',
    loadChildren: () => import('./vsk-screening/vsk-screening.module').then(m => m.VskScreeningModule)
  },
  {
    path: 'vsk-rrt-screening',
    loadChildren: () => import('./vsk-rrt-screening/vsk-rrt-screening.module').then(m => m.VskRrtScreeningModule)
  },
  {
    path: 'quote-retrieve',
    pathMatch: 'full',
    loadChildren: () => import('./quote-retrieve/quote-retrieve.module').then(m => m.QuoteRetrieveModule)
  },
  {
    path: 'quote-generate',
    pathMatch: 'full',
    loadChildren: () => import('./quote-generate/quote-generate.module').then(m => m.QuoteGenerateModule)
  },
  {
    path: 'quote',
    pathMatch: 'full',
    loadChildren: () => import('./quote/quote.module').then(m => m.QuoteModule)
  },
  {
    path: 'personal',
    pathMatch: 'full',
    component: PersonalComponent,
    data: {
      title: 'Personal Details',
      progStepIndex: 3
    }
  },
  {
    path: 'summary',
    pathMatch: 'full',
    loadChildren: () => import('./summary/summary.module').then(m => m.SummaryModule)
  },
  {
    path: 'payment',
    pathMatch: 'full',
    component: PaymentComponent,
    data: {
      title: 'Payment Details',
      progStepIndex: 5
    }
  },
  {
    path: 'payment-auth',
    pathMatch: 'full',
    component: PaymentAuthComponent,
    data: {
      title: 'Cardholder Authentication',
      progStepIndex: 5
    }
  },
  {
    path: 'confirmation',
    pathMatch: 'full',
    loadChildren: () => import('./confirmation/confirmation.module').then(m => m.ConfirmationModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule)
  },
  {
    path: 'quote-cannot-edit',
    pathMatch: 'full',
    component: QuoteCannotEditComponent,
    data: {
      title: 'Cannot Edit Quote'
    }
  },
  {
    path: 'no-certificate-loaded',
    pathMatch: 'full',
    component: NoCertificateLoadedComponent,
    data: {
      title: 'No Certificate Loaded'
    }
  },
  {
    path: '**',
    component: NotFoundComponent,
    data: { title: 'Not Found' }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
