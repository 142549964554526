import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { CampaignService } from '../services/campaign.service';
import { ImageInfo } from '../models/image-info';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-campaign-logo',
  templateUrl: './campaign-logo.component.html',
  styleUrls: ['./campaign-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CampaignLogoComponent implements OnInit, OnDestroy {

  public campaignLogoViewModel$: Observable<{ logoImageInfo: ImageInfo,
                                              logoImagePath: string }>;

  constructor(private _campaignService: CampaignService) {

  }

  ngOnInit(): void {

    this.campaignLogoViewModel$ = this._campaignService.activeCampaign
      .pipe(
        filter(camp => camp?.logoImage !== null),
        map(camp => {
          return {
            logoImageInfo: camp.logoImage,
            logoImagePath: `/assets/images/shared/${camp.logoImage.fileName}`
          };
        })
      );

  }

  ngOnDestroy(): void {
    
  }

}