<div class="grid-main-content">
  <main class="item-main">
  
    <div class="common-intro">
      <h1 class="common-intro__hdr">Quote Cannot Be Edited</h1>
    </div>
    <div class="common-char-width-limit">
      <p>You are seeing this message because an attempt was made to update an existing quote.</p>
      <p>These are the options available to you:</p>
      <ul>
        <li>Click 'Buy' to proceed to purchase the policy for this quote.</li>
        <li>Click 'New Quote' to begin entering information for a new quote.</li>
      </ul>
    </div>
    
    <mat-divider class="common-margin-md-y"></mat-divider>
    
    <div class="primary-actions-container common-margin-lg-y">
      <div class="primary-actions">
        <button [routerLink]="['/payment']" tabindex="0" class="primary-actions__btn" type="button" mat-raised-button color="accent" aria-label="Buy">Buy</button>
        <button [routerLink]="['/']" tabindex="0" class="primary-actions__btn" type="button" mat-raised-button color="primary" aria-label="New Quote">New Quote</button>
      </div>
    </div>

  </main>
</div>