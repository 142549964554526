<div class="grid-main-content">
  <main class="item-main">
  
    <div class="common-intro">
      <h1 class="common-intro__hdr">No Certificate Loaded</h1>
    </div>
    <div class="common-char-width-limit">
      <p>You are seeing this message because you may have arrived at this site via a bookmarked link and there is no quote in progress.</p>
      <p>These are the options available to you:</p>
      <ul>
        <li>Click 'Retrieve Quote' to retrieve an existing quote.</li>
        <li>Click 'New Quote' to begin entering information for a new quote.</li>
      </ul>
    </div>
    
    <mat-divider class="common-margin-md-y"></mat-divider>
    
    <div class="primary-actions-container common-margin-lg-y">
      <div class="primary-actions">
        <button [routerLink]="['/quote-retrieve']" tabindex="0" class="primary-actions__btn" type="button" mat-raised-button color="accent" aria-label="Retrieve Quote">Retrieve Quote</button>
        <button [routerLink]="['/']" tabindex="0" class="primary-actions__btn" type="button" mat-raised-button color="primary" aria-label="New Quote">New Quote</button>
      </div>
    </div>

  </main>
</div>