export enum MagentaSource {

  Connect = 1,
  Idol = 2,
  IdolCompareTheMarket = 3,
  IdolTescoGoCompare = 4,
  IdolGoCompare = 5,
  IdolConfused = 6,
  CYTIDirect = 7,
  CYTIMSMV1 = 8,
  CYTTIS = 9,
  CYTIMTC = 10,
  CYTIPhoenix = 11,
  CYTIMSM = 12,
  CYTITSM = 13,
  GoogleCompare = 14,
  QuoteZone = 15,
  TravelAdder = 16,
  Portal = 17,
  CTM = 18,
  MSM = 19,
  PetConfused = 20,
  PetTheIdol = 21,
  PetPayingTooMuch = 22,
  PetCompareTheMarket = 23,
  PetGoCompare = 24,
  PetQuoteZone = 25,
  PetMoneySupermarket = 26,
  Import = 27,
  PayingTooMuch = 28,
  IdolUSwitch = 29,
  IdolMoney = 30,
  Rastreator = 31,
  Acierto = 32,
  PetForbes = 33,
  PetEveningStandard = 34,
  PetQuidco = 35,
  AequotechMTC = 36,
  AequotechCYTICanada = 37,
  AequotechCYCIUK = 38,
  AequotechForbesCA = 39,
  AequotechCYTIUK = 40,
  AequotechUSNewsUK = 41
  
}