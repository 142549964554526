import { Component, OnInit, AfterViewInit, OnDestroy, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigService } from '../services/app-config.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { PaymentService } from '../services/payment.service';
import { TransientThreeDSecureData } from '../models/transient-three-d-secure-data';
import { CustomMerchantData } from '../models/custom-merchant-data';

@Component({
  selector: 'app-payment-auth',
  templateUrl: './payment-auth.component.html',
  styleUrls: ['./payment-auth.component.scss']
})
export class PaymentAuthComponent implements OnInit, AfterViewInit, OnDestroy {

  private _isDataAvailable: boolean;
  private _redirectUrl: string; // Form action
  private _paReq: string;
  private _md: string;
  private _termUrl: string;

  private _threeDSecureVersion: number;
  private _threeDSServerTransId: string;

  constructor(private _ngZone: NgZone,
              private _appConfigService: AppConfigService,
              private _spinnerService: NgxSpinnerService,
              private _router: Router,
              private _paymentService: PaymentService) {

    // Load the transient data.
    const transientThreeDSecureData = this._paymentService.transientThreeDSecureData;
    this._isDataAvailable = transientThreeDSecureData !== null;

    if(!this._isDataAvailable) return;

    const customMerchantData: CustomMerchantData = {
      agentId: this._appConfigService.agentId,
      certificateId: transientThreeDSecureData.certificateId,
      transactionId: transientThreeDSecureData.transactionId,
      noMail: transientThreeDSecureData.noMail
    };

    this._redirectUrl = transientThreeDSecureData.redirectUrl;
    this._paReq = transientThreeDSecureData.paReq;
    this._md = JSON.stringify(customMerchantData);
    this._termUrl = transientThreeDSecureData.termUrl;
    this._threeDSecureVersion = transientThreeDSecureData.threeDSecureVersion;

    if(this._threeDSecureVersion === 2) {
      this._threeDSServerTransId = transientThreeDSecureData.threeDSServerTransId;
    }
  }

  private iframeLoaded(): void {
    this._spinnerService.hide();
  }

  ngOnInit(): void {

    if(this._isDataAvailable === false) this._router.navigate(['/payment']);

  }

  ngAfterViewInit(): void {

    if(!this._isDataAvailable) return;

    this._spinnerService.show();

    const iframe = document.getElementById('three-ds-iframe-form-container') as HTMLIFrameElement;
    iframe.onload = this.iframeLoaded.bind(this);

    this._ngZone.runOutsideAngular(() => {

      // Submit for 3DS v1.
      if(this._threeDSecureVersion === 1) {
        const formEl = document.getElementById('three-ds-form') as HTMLFormElement;
        formEl.action = this._redirectUrl;
      
        const paReqFormEl = document.getElementById('PaReq') as HTMLInputElement;
        paReqFormEl.value = this._paReq;

        const mdFormEl = document.getElementById('MD') as HTMLInputElement;
        mdFormEl.value = this._md;

        const termUrlFormEl = document.getElementById('TermUrl') as HTMLInputElement;
        termUrlFormEl.value = this._termUrl;

        formEl.submit();
      }

      // Submit for 3DS v2.
      if(this._threeDSecureVersion === 2) {
        const formEl = document.getElementById('three-ds-sca-form') as HTMLFormElement;
        formEl.action = this._redirectUrl;

        const transIdFormEl = document.getElementById('transactionId') as HTMLInputElement;
        transIdFormEl.value = this._threeDSServerTransId;

        const mdFormEl = document.getElementById('MD-sca') as HTMLInputElement;
        mdFormEl.value = this._md;

        const notificationUrlFormEl = document.getElementById('notificationUrl') as HTMLInputElement;
        notificationUrlFormEl.value = this._termUrl;

        formEl.submit();
      }
      
    });

  }

  ngOnDestroy(): void {
    this._spinnerService.hide();
  }

}
