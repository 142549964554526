<div class="grid-main-content">
  <main class="item-main">

    <div class="common-intro">
      <h1 class="common-intro__hdr">Final Security Checks</h1>
    </div>
    
    <div class="iframe-container common-margin-md-y">
      <iframe id="three-ds-iframe-form-container"
              name="three-ds-iframe-form-container"
              class="three-ds-iframe"
              frameborder="0"></iframe>
    </div>

    <!-- 3DS v1 auto submit form -->
    <form id="three-ds-form" method="POST" name="three-ds-form" target="three-ds-iframe-form-container">
      <input id="PaReq" type="hidden" name="PaReq" />
      <input id="MD" type="hidden" name="MD" />
      <input id="TermUrl" type="hidden" name="TermUrl" />
    </form>

    <!-- 3DS v2 auto submit form -->
    <form id="three-ds-sca-form" method="POST" name="three-ds-sca-form" target="three-ds-iframe-form-container">
      <input id="transactionId" type="hidden" name="transactionId" /> <!-- threeDSServerTransId -->
      <input id="MD-sca" type="hidden" name="MD" />
      <input id="notificationUrl" type="hidden" name="notificationUrl" />
    </form>

  </main>
</div>