import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigService } from './app-config.service';
import { CookieService } from 'ngx-cookie-service';
import { PolicyService } from '../services/policy.service';
import { CytiData } from '../models/tracking/cyti-data';
import { IdolData } from '../models/tracking/idol-data';
import { QuotezoneData } from '../models/tracking/quotezone-data';
import { AwinServerToServerRequest } from '../models/dto/tracking/awin-server-to-server-request';
import { Certificate } from '../models/magenta/certificate';
import { Scheme } from '../models/magenta/scheme';
import { GaQuoteData } from '../models/tracking/ga-quote-data';
import { GaQuoteCountryData } from '../models/tracking/ga-quote-country-data';
import { GaQuoteClientData } from '../models/tracking/ga-quote-client-data';
import { GaQuoteSchemeData } from '../models/tracking/ga-quote-scheme-data';

declare global {
  interface Window { dataLayer: any[] }
}

@Injectable({
  providedIn: 'root'
})
export class TrackingService {

  private readonly _idolDataSessionKey = 'tracking_svc_idol_data';
  private readonly _quotezoneDataSessionKey = 'tracking_svc_quotezone_data';
  private readonly _cytiDataSessionKey = 'tracking_svc_cyti_data';
  private readonly _awinAwcSessionKey = 'tracking_svc_awin_awc';
  private readonly _awinAwcCookieName = 'awin_s2s_awc';
  private readonly _awinAwcUrlParamName = 'awc';
  private readonly _ga4CurrencyCode = 'GBP';
  private readonly _confGaEcomTrackingFiredSessionKey = 'tracking_svc_conf_ga_ecom_fired';
  private readonly _confAwinTrackingFiredSessionKey = 'tracking_svc_conf_awin_fired';
  private readonly _confCytiTrackingFiredSessionKey = 'tracking_svc_conf_cyti_fired';

  constructor(private _appConfigService: AppConfigService,
              private _window: Window,
              private _http: HttpClient,
              private _cookieService: CookieService,
              private _policyService: PolicyService) {
    
  }

  /**
   * @deprecated
   * Gets value indicating whether Idol tracking data is available.
   */
  public isIdolDataAvailable(): boolean {

    return sessionStorage.getItem(this._idolDataSessionKey) !== null;

  }

  /**
   * @deprecated
   * Sets session var used to store Idol tracking data.
   */
  public setIdolData(idolData: IdolData): void {

    sessionStorage.setItem(this._idolDataSessionKey, JSON.stringify(idolData));

  }

  /**
   * @deprecated
   * Gets Idol tracking data stored in session.
   */
  public getIdolData(): IdolData {

    const idolData = JSON.parse(sessionStorage.getItem(this._idolDataSessionKey));

    return idolData;

  }

  /**
   * @deprecated
   * Returns the tracking beacon img tag HTML required for Idol traffic confirmation.
   */
  public getIdolConfirmationTrackingTag(externalPolicyReference: string, totalGross: number): string {

    const baseUrl = this._appConfigService.agent.theIdol.salesTrackingTagBaseUrl;
    
    return `<img src="${baseUrl}?ref=${externalPolicyReference}&fullprem=${totalGross.toString()}" height="0" width="0" border="0" />`;

  }

  /**
   * Gets value indicating whether Quotezone tracking data is available.
   */
  public isQuotezoneDataAvailable(): boolean {

    return sessionStorage.getItem(this._quotezoneDataSessionKey) !== null;

  }

  /**
   * Sets session var used to store Quotezone tracking data.
   */
  public setQuotezoneData(quotezoneData: QuotezoneData): void {

    sessionStorage.setItem(this._quotezoneDataSessionKey, JSON.stringify(quotezoneData));

  }

  /**
   * Gets Quotezone tracking data stored in session.
   */
  public getQuotezoneData(): IdolData {

    const quotezoneData = JSON.parse(sessionStorage.getItem(this._quotezoneDataSessionKey));

    return quotezoneData;

  }

  /**
   * Gets value indicating whether CYTI tracking data is available.
   */
  public isCytiDataAvailable(): boolean {

    return sessionStorage.getItem(this._cytiDataSessionKey) !== null;

  }

  /**
   * Sets session var used to store CYTI tracking data.
   */
  public setCytiData(cytiData: CytiData): void {

    sessionStorage.setItem(this._cytiDataSessionKey, JSON.stringify(cytiData));

  }

  /**
   * Gets CYTI tracking data stored in session.
   */
  public getCytiData(): CytiData {

    const cytiData = JSON.parse(sessionStorage.getItem(this._cytiDataSessionKey));

    return cytiData;

  }

  /**
   * Returns the tracking beacon img tag HTML required for CTYI traffic landing on payment page.
   */
  public getCytiPaymentTrackingTag(cytiData: CytiData, totalGross: number): string {

    return `<img src="${cytiData.platformUrl}track/click?cref=${cytiData.cref}&premium=${totalGross.toString()}&t=${cytiData.token}" height="0" width="0" border="0" />`;

  }

  /**
   * Returns the tracking beacon img tag HTML required for CTYI traffic confirmation.
   */
  public getCytiConfirmationTrackingTag(cytiData: CytiData, policyReference: string, totalGross: number): string {

    return `<img src="${cytiData.platformUrl}track/confirm?cref=${cytiData.cref}&policyreference=${policyReference}&premium=${totalGross.toString()}&t=${cytiData.token}" height="0" width="0" border="0" />`;

  }

  /**
   * Sets the value of the GA ecommerce tracking fired policy reference to session.
   */
  public setConfGaEcomTrackingFired(policyReference: string): void {

    sessionStorage.setItem(this._confGaEcomTrackingFiredSessionKey, policyReference);

  }

  /**
   * Returns a value indicating whether GA ecommerce tracking has been fired for the given policy reference.
   */
  public hasConfGaEcomTrackingFired(policyReference: string): boolean {

    return sessionStorage.getItem(this._confGaEcomTrackingFiredSessionKey) === policyReference;

  }

  /**
   * Sets the value of the Awin tracking fired policy reference to session.
   */
  public setConfAwinTrackingFired(policyReference: string): void {

    sessionStorage.setItem(this._confAwinTrackingFiredSessionKey, policyReference);

  }

  /**
   * Returns a value indicating whether Awin tracking has been fired for the given policy reference.
   */
  public hasConfAwinTrackingFired(policyReference: string): boolean {

    return sessionStorage.getItem(this._confAwinTrackingFiredSessionKey) === policyReference;

  }

  /**
   * Sets the value of the CYTI tracking fired policy reference to session.
   */
  public setConfCytiTrackingFired(policyReference: string): void {

    sessionStorage.setItem(this._confCytiTrackingFiredSessionKey, policyReference);

  }

  /**
   * Returns a value indicating whether CYTI tracking has been fired for the given policy reference.
   */
  public hasConfCytiTrackingFired(policyReference: string): boolean {

    return sessionStorage.getItem(this._confCytiTrackingFiredSessionKey) === policyReference;

  }

  /**
   * Populates data for GA quote event payload.
   * Not setting quotedSchemes causes the returned object to be configured 
   * in a way suitable for pushing quote data sale confirmation.
   */
  public populateGaQuoteData(certificate: Certificate, quotedSchemes?: Scheme[]): GaQuoteData {
    const gaQuoteData = new GaQuoteData();

    gaQuoteData.certificateId = certificate.certificateId;
    gaQuoteData.schemeGroup = certificate.schemeGroup;
    gaQuoteData.policyType = certificate.policyType;
    gaQuoteData.durationDays = certificate.duration;
    gaQuoteData.campaignId = certificate.campaign;
    gaQuoteData.campaignName = certificate.campaignName;
    gaQuoteData.destinationId = certificate.destination;
    gaQuoteData.destinationName = certificate.destinationName;
    gaQuoteData.clients = [];

    // Populate countries.
    if(certificate?.countryIds?.length > 0) {
      gaQuoteData.countries = [];

      certificate.countryIds.forEach(countryId => {
        const gaQuoteCountryData: GaQuoteCountryData = {
          id: countryId,
          name: this._policyService.getCountryNameById(countryId, certificate.scheme.destinations)
        };

        gaQuoteData.countries.push(gaQuoteCountryData);
      });
    }

    // Populate clients.
    certificate.clients.forEach(client => {
      const gaQuoteClientData: GaQuoteClientData = {
        clientId: client.clientId,
        certificateClientId: client.certificateClientId,
        age: this._policyService.calculateClientAgeFromDob(client.dateOfBirth),
        screeningScore: client?.screening?.score ?? 0,
        screeningConditionCount: client?.screening?.conditions?.length ?? 0
      };

      gaQuoteData.clients.push(gaQuoteClientData);
    });

    // When quoted schemes available, this is a push for scheme views, not confirmation.
    if(quotedSchemes !== undefined) {
      gaQuoteData.schemes = [];

      quotedSchemes.forEach(quotedScheme => {
        const gaSchemeData: GaQuoteSchemeData = {
          id: quotedScheme.id,
          name: quotedScheme?.friendlyName ?? '',
          premiumTotalGross: quotedScheme.premium.totalGross
        };

        gaQuoteData.schemes.push(gaSchemeData);
      });
    }

    // When no quotedSchemes available, this is a confirmation push
    // so set ref and scheme to selected scheme.
    if(quotedSchemes === undefined) {
      gaQuoteData.policyReference = certificate.policyReference;

      const scheme: GaQuoteSchemeData = {
        id: certificate.scheme.id,
        name: certificate.scheme?.friendlyName ?? '',
        premiumTotalGross: certificate.overallGross
      };

      gaQuoteData.schemes = [ scheme ];
    }

    return gaQuoteData;
  }

  /**
   * Fires 'quote' event to Google Analytics.
   */
  public gaFireQuoteEventTracking(certificate: Certificate, quotedSchemes: Scheme[]): void {
    const gaQuoteData = this.populateGaQuoteData(certificate, quotedSchemes);

    this._window.dataLayer.push({
      event: 'quote',
      quote: gaQuoteData
    });
  }

  /**
   * Fires Google Analytics ecommerce tracking. Pushes sales data to GA's dataLayer.
   * Updated to include quote data push.
   */
  public gaFireEcommerceTracking(certificate: Certificate): void {

    const gaQuoteData = this.populateGaQuoteData(certificate);
    const brandName = this._appConfigService.agent.name;
    const groupName = this._appConfigService.getAgentFamilyGroupById(certificate.familyGroup).name;

    this._window.dataLayer.push({
      event: 'transaction',
      ecommerce: {
        purchase: {
          actionField: {
            id: certificate.policyReference,                // Transaction ID. Required for purchases and refunds - Cover Reference
            affiliation: certificate.campaignName,          // Optional - AffiliateCode
            revenue: certificate.overallGross.toString(),   // Total transaction value (incl. tax and shipping)
            tax: '0',                                       // Optional
            coupon: ''                                      // Optional
          },
          products: [{                                      // List of productFieldObjects.
            name: certificate.scheme?.friendlyName ?? '',                  // Name or ID is required.
            id: certificate.policyReference,                // Cover Reference
            price: certificate.overallGross.toString(),
            brand: brandName,                               // Optional
            category: groupName,                            // Optional - Group Type
            variant: certificate.destinationName,           // Optional - Destination
            quantity: 1,
            coupon: ''                                      // Optional fields may be omitted or set to empty string.
           }],
        }
      }
    });

    // Additional GA4 version of the ecommerce dataLayer push.
    // The above push to be removed when this confirmed working.
    this._window.dataLayer.push({
      event: 'transaction_ga4',
      ecommerce: {
        transaction_id: certificate.policyReference,
        affiliation: certificate.campaignName,
        value: certificate.overallGross,
        tax: 0,
        shipping: 0,
        currency: this._ga4CurrencyCode,
        coupon: '',
        items: [{
          item_id: certificate.policyReference,
          item_name: certificate.scheme?.friendlyName ?? '',
          // affiliation: {{NEW}},
          coupon: '',
          currency: this._ga4CurrencyCode,
          // discount: {{NEW}},
          // index: {{products.position}},
          item_brand: brandName,
          item_category: groupName,
          // item_list_id: {{NEW}},
          // item_list_name: {{products.name}},
          item_variant: certificate.destinationName,
          price: certificate.overallGross,
          quantity: 1
        }]
      },
      quote: gaQuoteData,
      email: certificate.clients[0].email,
      marketingOptIn: !certificate.clients[0].noMail
    });

  }

  /**
   * Fires Distil identify customer GA event.
   */
  public gaFireDistilIdentifyCustomerEvent(certificate: Certificate): void {
    if(!certificate?.clients[0]) return;

    const optIn = !certificate.clients[0].noMail;

    this._window.dataLayer.push({
      event: 'distil.identifyCustomer',
      customerProperties: {
        EmailAddress: certificate.clients[0].email,
        GDPRStatus: {
          MarketingSubscribed: optIn
        }
      }
    });
  }

  /**
   * Gets the name of the incoming URL param used by AWIN to set awc checksum value.
   */
  public get awinAwcUrlParamName() : string {
    return this._awinAwcUrlParamName;
  }
  
  /**
   * Gets the previously set AWIN AWC checksum, or empty string if no value available.
   */
  public awinSetAwcChecksumCookie(awcChecksum: string): void {
    
    sessionStorage.setItem(this._awinAwcSessionKey, awcChecksum);
    this._cookieService.set(this._awinAwcCookieName, awcChecksum, 30, null, this._appConfigService.agent.cookieDomain, true, 'Strict');
    
  }

  /**
   * Returns value indicating whether AWIN AWC cookie is set.
   */
  public awinIsAwcCookieSet(): boolean {

    return this._cookieService.check(this._awinAwcCookieName);

  }
  
  /**
   * Gets the previously set AWIN AWC checksum, or null if no value available.
   */
  public awinGetPreviouslySetAwcChecksum(): string {
    
    const awcChecksumValue = sessionStorage.getItem(this._awinAwcSessionKey);
    if(awcChecksumValue) return awcChecksumValue;
    if(this.awinIsAwcCookieSet()) return this._cookieService.get(this._awinAwcCookieName);
    return null;

  }

  /**
   * Sends request to process AWIN server 2 server to be used post purchase.
   */
  public awinServerToServerRequest(awinServerToServerRequest: AwinServerToServerRequest): Observable<void> {

    const url = `${this._appConfigService.quoteApiBaseUrl}/Tracking/AwinServerToServer`;

    return this._http.post<void>(url, awinServerToServerRequest);

  }

}
