<footer *ngIf="footerViewModel$ | async as footerViewModel" class="ftr">
  <div class="common-container">
    <div class="ftr__cards"><img class="ftr__cards-img" width="346" height="75" alt="All major credit and debit cards accepted, PayPal and more..." src="assets/images/shared/card-logos-row.svg" /></div>
  </div>
  <div class="ftr-content-wrapper" [style.background-color]="footerViewModel.displayData.brandColourPrimary">
    <div class="common-container">
      <div class="ftr-content-container">
        <div class="ftr-content" [innerHTML]="footerViewModel.displayData.footerContent"></div>
        <div class="ftr-contact">
          <ul class="ftr__contact-list">
            <li class="ftr__contact-list-item ftr__contact-list-item--tel"><a class="ftr__link" [href]="'tel:' + footerViewModel.campaign?.telephoneNumber">{{ footerViewModel.campaign?.telephoneNumber }}</a></li>
            <li class="ftr__contact-list-item ftr__contact-list-item--email"><a class="ftr__link" [href]="'mailto:' + footerViewModel.displayData.generalEmail">{{ footerViewModel.displayData.generalEmail }}</a></li>
            <li class="ftr__contact-list-item ftr__contact-list-item--web"><a class="ftr__link" [href]="footerViewModel.displayData.customerSiteUrl">{{ footerViewModel.displayData.customerSiteUrlDisplay }}</a></li>
            <li class="ftr__contact-list-item ftr__contact-list-item--retrieve"><a class="ftr__link" [routerLink]="['/quote-retrieve']">Retrieve Existing Quote</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</footer>