<div class="grid-main-content">
  <main class="item-main">

    <div class="common-intro">
      <h1 class="common-intro__hdr">Payment Details</h1>
    </div>

    <p *ngIf="certificate && showForm" class="payment-amount"><strong>Total Amount:</strong> {{ certificate?.overallGross | currency }}</p>

    <mat-card *ngIf="showExternalLoadError">
      <mat-card-title>
        Error
      </mat-card-title>
      <mat-card-content>
        <p>An error occurred while loading your policy. Cannot continue.</p>
        <p>Please call the number at the top of this page for further assistance.</p>
      </mat-card-content>
    </mat-card>

    <mat-card *ngIf="showMagentaServiceError">
      <mat-card-title>
        Service Error
      </mat-card-title>
      <mat-card-content>
        <p>{{ magentaServiceErrorDesc }}</p>
      </mat-card-content>
    </mat-card>

    <mat-card *ngIf="showCannotPurchasePolicyError">
      <mat-card-title>
        Policy Cannot Be Purchased
      </mat-card-title>
      <mat-card-content>
        <p>The policy you are attempting to purchase may have already been issued. If you have any queries please call the number at the top of this page.</p>
      </mat-card-content>
    </mat-card>

    <ng-container *ngIf="certificate && showForm">

      <mat-divider class="common-margin-md-y"></mat-divider>

      <form [formGroup]="paymentForm" (ngSubmit)="onSubmit($event)">

        <h2>Choose a Payment Method</h2>
        <div class="method-select-container">
          <mat-radio-group id="payment-method" aria-label="Select your preferred payment method" formControlName="paymentMethod">
            <mat-radio-button id="payment-method-card"
                              #card
                              tabindex="0"
                              class="method-select-radio method-select-radio--card"
                              ariaLabel="Pay by Card"
                              [value]="supportedPaymentMethodEnum.Card"
                              [ngClass]="{ 'method-select-radio--selected': card.checked }">Pay by Card</mat-radio-button>
            <mat-radio-button *ngIf="isEnvProdMode === false && isApplePaySupported && isApplePayAvailable"
                              id="payment-method-applepay"
                              #applepay
                              tabindex="0"
                              class="method-select-radio method-select-radio--apple-pay"
                              ariaLabel="Pay with Apple Pay"
                              [value]="supportedPaymentMethodEnum.ApplePay"
                              [ngClass]="{ 'method-select-radio--selected': applepay.checked }">Pay with Apple Pay</mat-radio-button>
            <mat-radio-button *ngIf="isPayPalSupported"
                              id="payment-method-paypal"
                              #paypal
                              tabindex="0"
                              class="method-select-radio method-select-radio--paypal"
                              ariaLabel="Pay with PayPal"
                              [value]="supportedPaymentMethodEnum.PayPal"
                              [ngClass]="{ 'method-select-radio--selected': paypal.checked }">Pay with PayPal</mat-radio-button>
          </mat-radio-group>
        </div>

        <div class="paypal-hdr-container" *ngIf="paymentForm.controls.paymentMethod.value === supportedPaymentMethodEnum.ApplePay">
          <h2>Paying with Apple Pay</h2>
          <p>Click 'Pay with Apple Pay' below.</p>
        </div>

        <div class="paypal-hdr-container" *ngIf="paymentForm.controls.paymentMethod.value === supportedPaymentMethodEnum.PayPal">
          <h2>Paying with PayPal</h2>
          <p>Click 'Pay with PayPal' below to be transferred to PayPal.</p>
        </div>

        <ng-container *ngIf="paymentForm.controls.paymentMethod.value === supportedPaymentMethodEnum.Card"
                      formGroupName="card">
          <div class="card-hdr-container">
            <h2>Paying by Card</h2>
            <p>Enter your card details and click 'Pay by Card' below.</p>
          </div>
          <div class="form-group-container">
            <div class="form-item">
              <mat-form-field>
                <input id="cc-name" name="cc-name" tabindex="0" autocomplete="off" formControlName="cardHolderName" type="text" matInput placeholder="Name on Card" />
                <mat-hint>Name as it appears on card.</mat-hint>
                <mat-error *ngIf="paymentForm.get('card.cardHolderName').errors?.required">Please enter the name as it appears on the card.</mat-error>
                <mat-error *ngIf="paymentForm.get('card.cardHolderName').errors?.minlength">Name on card must be 2 characters or more.</mat-error>
                <mat-error *ngIf="paymentForm.get('card.cardHolderName').errors?.maxlength">Name on card must be under 45 characters.</mat-error>
              </mat-form-field>
            </div>
            <div class="form-item">
              <mat-form-field>
                <input id="cc-number" name="cc-number" tabindex="0" autocomplete="off" formControlName="pan" type="text" matInput placeholder="Card Number" />
                <mat-hint>The long number on the front of the card.</mat-hint>
                <mat-error *ngIf="paymentForm.get('card.pan').errors?.required">Please enter the card number. The long number on the front of the card.</mat-error>
                <mat-error *ngIf="paymentForm.get('card.pan').errors?.pattern">Please check the card number. It should be between 13 and 19 digits in length and contain no spaces.</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="form-group-container">
            <div class="form-item form-item--inline">
              <mat-form-field class="expiry-month-field">
                <mat-label>Expiry Month</mat-label>
                <mat-select id="cc-exp-month" name="cc-exp-month" tabindex="0" formControlName="expiryMonth" placholder="">
                  <mat-option value=""></mat-option>
                  <mat-option *ngFor="let expiryMonth of expiryMonths" [value]="expiryMonth">{{ expiryMonth }}</mat-option>
                </mat-select>
                <mat-error *ngIf="paymentForm.get('card.expiryMonth').errors?.required">Expiry month required.</mat-error>
              </mat-form-field>
              <mat-form-field class="expiry-year-field">
                <mat-label>Expiry Year</mat-label>
                <mat-select id="cc-exp-year" name="cc-exp-year" tabindex="0" formControlName="expiryYear" placholder="">
                  <mat-option value=""></mat-option>
                  <mat-option *ngFor="let expiryYear of expiryYears" [value]="expiryYear">{{ expiryYear }}</mat-option>
                </mat-select>
                <mat-error *ngIf="paymentForm.get('card.expiryYear').errors?.required">Expiry year required.</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="form-group-container form-group-container--cvv">
            <div class="form-item form-item--inline">
              <mat-form-field>
                <input id="cc-csc" name="cc-csc" tabindex="0" autocomplete="off" formControlName="cv2" type="text" matInput placeholder="CV2 Number" mask="000" />
                <mat-hint>Rightmost three digits printed on the signature strip.</mat-hint>
                <mat-error *ngIf="paymentForm.get('card.cv2').errors?.required">Please enter the CV2 number (rightmost three digits printed on the signature strip).</mat-error>
                <mat-error *ngIf="paymentForm.get('card.cv2').errors?.pattern">Please check the CV2 number. 3 digits are required.</mat-error>
              </mat-form-field>
              <button tabindex="0" type="button" mat-icon-button aria-label="Help" (click)="openCommonDialog('Cv2')">
                <mat-icon>help</mat-icon>
              </button>
            </div>
          </div>
        </ng-container>

        <mat-divider class="common-margin-md-y"></mat-divider>

        <div class="billing-address-container">
          <h2>Billing Address</h2>
          <div class="form-group-container">
            <div class="form-item form-item--inline">
              <mat-checkbox tabindex="0"
                            formControlName="usePolicyForBillingAddress">
                <span class="opt-checkbox">Use lead traveller address for billing address.</span>
              </mat-checkbox>
            </div>
          </div>

          <p *ngIf="paymentForm.get('usePolicyForBillingAddress').value === true; else billingAddress">{{ policyAddressAsOneLine }}</p>
          <ng-template #billingAddress>
            <app-address-entry [isPaymentMode]="true"
                               [isoCountries]="isoCountries"
                               [postcodeSearchFormGroupName]="postcodeSearchFormGroupName"
                               [addressFormGroupName]="addressFormGroupName">
            </app-address-entry>
          </ng-template>
        </div>

        <mat-divider class="common-margin-md-y"></mat-divider>

        <div formGroupName="marketingAndReqs" class="common-char-width-limit">
          <div *ngIf="isCyti" class="common-margin-md-y">
            <h2>Privacy</h2>
            <div [innerHTML]="optInStatementContent"></div>
            <mat-checkbox tabindex="0"
                          formControlName="optInMail">
              <span class="opt-checkbox" [innerHTML]="optInStatementCheckboxLabelContent"></span>
            </mat-checkbox>
          </div>

          <h2>Agree Terms</h2>
          <div [innerHTML]="insurerDisclaimerContent"></div>

          <p>Our <a role="button" tabindex="0" (click)="openCommonDialog('Terms')">terms of business</a> sets out the basis on which we agree to act for our clients. We will send your documents by email. Please consider the environmental impact before requesting your documents by post.</p>
          <p>You must read the <a [href]="certificate?.scheme?.ipidFileUrl" target="_blank">insurance product information document</a> and <a [href]="certificate?.scheme?.policyWordingDocumentUrl" target="_blank">policy wording</a> with the understanding that these form the basis of your insurance contract. It is important that you read both documents carefully to ensure the cover fully meets your needs.</p>
          <p>If you change your mind or become aware that the cover does not meet your needs you can cancel the policy and receive a full refund within 14 days as long as you haven't travelled or made a claim.</p>

          <mat-checkbox tabindex="0"
                        formControlName="readHealthReqs">
            <span *ngIf="isCyti === false" class="opt-checkbox">Please confirm that you have read and understood all the above statements and agree to continue</span>
            <span *ngIf="isCyti === true" class="opt-checkbox">Please confirm that you agree to continue and have read and understood all the above statements and consent to us using your personal data to administer your insurance in accordance with our <a [href]="privacyNoticeUrl" target="_blank">privacy policy</a></span>
          </mat-checkbox>
          
          <p class="error-text" *ngIf="paymentForm.get('marketingAndReqs.readHealthReqs').errors && paymentFormSubmitted === true">Please confirm you agree and are happy to proceed.</p>
        </div>

        <mat-divider class="common-margin-md-y"></mat-divider>
    
        <div class="primary-actions-container common-margin-lg-y">
          <div *ngIf="paymentForm.status === 'INVALID' && paymentFormSubmitted" class="card-error">
            <p class="error-text">Please review any highlighted fields above before continuing.</p>
          </div>
          <div class="primary-actions">
            <button *ngIf="showBackBtn === true" tabindex="0" class="primary-actions__btn" type="button" mat-raised-button aria-label="Back" (click)="backOnClick()"><mat-icon inline="true" aria-hidden="true">chevron_left</mat-icon>Back</button>
            <button id="submitBtn" [disabled]="disableSubmit" tabindex="0" class="primary-actions__btn primary-actions__btn--pay" type="submit" mat-raised-button color="primary" aria-label="Pay">{{ submitBtnText }}<mat-icon inline="true" aria-hidden="true">lock</mat-icon></button>
          </div>
        </div>

      </form>

    </ng-container>

    <!-- Start tracking tags -->
    <div *ngIf="isCyti && cytiPaymentTrackingTag" [innerHTML]="cytiPaymentTrackingTag" role="none"></div>
    <!-- End tracking tags -->

  </main>
  <aside class="item-aside">
    <app-campaign-logo></app-campaign-logo>
    <app-sidebar-summary [displayFinancials]="true" [displayMedicalScreenings]="true"></app-sidebar-summary>
  </aside>
</div>