import { GaQuoteCountryData } from './ga-quote-country-data';
import { GaQuoteClientData } from './ga-quote-client-data';
import { GaQuoteSchemeData } from './ga-quote-scheme-data';

export class GaQuoteData {

  public certificateId: number;

  public policyReference?: string | null;

  public schemeGroup: number;

  public policyType: number;

  public durationDays: number;

  public campaignId: number;

  public campaignName: string;

  public destinationId: number;

  public destinationName: string;

  public countries?: GaQuoteCountryData[] | null;

  public clients: GaQuoteClientData[];

  public schemes: GaQuoteSchemeData[];

}