<header *ngIf="headerViewModel$ | async as headerViewModel" class="hdr mat-elevation-z3" [style.background-color]="headerViewModel.displayData.brandColourPrimary">
  <div class="common-container">
    <div class="hdr-container">
      <div class="hdr-logo">
        <img class="hdr__logo" [alt]="headerViewModel.displayData.agentName" [src]="headerViewModel.displayData.logoImgUrl" />
      </div>
      <div class="hdr-tel">
        <p class="hdr__tel"><a class="hdr__tel-link" [href]="'tel:' + headerViewModel.campaign?.telephoneNumber">{{ headerViewModel.campaign?.telephoneNumber }}</a></p>
        <p class="hdr__open">Open {{ headerViewModel.displayData.openingHours }}</p>
      </div>
    </div>
  </div>
</header>