export enum ScreeningResultEnum {

  CoverWithNoAdditionalPremium = 1,
  CoverWithAdditionalPremium = 2,
  CoverWithAdditionalPremiumAndExcess = 3,
  CoverWithAdditionalExcess = 4,
  CannotProvideMedicalCover = 5,
  CannotProvideInsurance = 6,
  IncludeAllConditions = 7,
  DecisionDeferred = 8,
  ReferToUnderwriter = 9,
  NoCoverForExcludedConditions = 10

}