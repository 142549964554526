import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Validators, FormGroup, FormGroupDirective } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSelectionListChange } from '@angular/material/list';
import { Subscription } from 'rxjs';
import { PostcodeSearchService } from '../services/postcode-search.service';
import { GetByPostcodeRequest } from '../models/dto/postcode-search/get-by-postcode-request';
import { PostcodeSearchAddress } from '../models/postcode-search/postcode-search-address';
import { PostcodeSearchResult } from '../models/postcode-search/postcode-search-result';
import { IsoCountry } from '../models/iso-country';


@Component({
  selector: 'app-address-entry',
  templateUrl: './address-entry.component.html',
  styleUrls: ['./address-entry.component.scss']
})

export class AddressEntryComponent implements OnInit, OnDestroy {

  public postcodeSearchForm: FormGroup;
  public postcodeSearchFormSubmitted = false;
  public postcodeSearchResults: PostcodeSearchResult[] = [];
  public showNoAddressFoundMessage = false;
  public addressForm: FormGroup;
  public findBtnDisabled = false;

  private _postcodeSearchFormGroupName: string;
  private _addressFormGroupName: string;
  private _isPaymentMode: boolean;
  private _isoCountries: IsoCountry[];
  private readonly _postcodeSearchSpinnerName = 'search';

  // Subscriptions.
  private readonly _subscriptions: Subscription[] = [];

  constructor(private _rootFormGroup: FormGroupDirective,
              private _spinnerService: NgxSpinnerService,
              private _postcodeSearchService: PostcodeSearchService) {
    
    this._isPaymentMode = false;

  }

  ngOnInit(): void {

    // Init postcodeSearch form.
    this.postcodeSearchForm = this._rootFormGroup.control.get(this._postcodeSearchFormGroupName) as FormGroup;

    // Init address form.
    this.addressForm = this._rootFormGroup.control.get(this._addressFormGroupName) as FormGroup;

    this._subscriptions.push(
      this.postcodeSearchForm.get('postcodeLookupId').valueChanges
        .subscribe(value => {
          const postcodeSearchResult = this.postcodeSearchResults.find(({ postcodeLookupId }) => value === postcodeLookupId);

          if(postcodeSearchResult !== undefined) {
            this.postcodeSearchResults = [];
            this.addressForm.patchValue(postcodeSearchResult.postcodeSearchAddress);
          }
        })
    );

  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  private postcodeSearchAddressToOneLine(postcodeSearchAddress: PostcodeSearchAddress): string {
    const addressLines = [postcodeSearchAddress.address1, postcodeSearchAddress.address4, postcodeSearchAddress.postcode];
    const filteredLines = addressLines.filter(addressLine => addressLine?.length > 0);
    return filteredLines.join(', ');
  }

  private populatePostcodeSearchResults(postcodeSearchAddresses: PostcodeSearchAddress[]): void {
    const results: PostcodeSearchResult[] = [];

    postcodeSearchAddresses.forEach(address => {
      const postcodeSearchResult: PostcodeSearchResult = {
        postcodeLookupId: address.postcodeLookupId,
        addressAsOneLine: this.postcodeSearchAddressToOneLine(address),
        postcodeSearchAddress: address
      };

      results.push(postcodeSearchResult);
    });

    this.postcodeSearchResults = results;
  }

  @Input()
  set isPaymentMode(paymentMode: boolean) {
    this._isPaymentMode = paymentMode;
  }

  get isPaymentMode(): boolean { return this._isPaymentMode; }

  @Input()
  set postcodeSearchFormGroupName(postcodeSearchFormGroupName: string) {
    this._postcodeSearchFormGroupName = postcodeSearchFormGroupName;
  }

  get postcodeSearchFormGroupName(): string { return this._postcodeSearchFormGroupName; }

  @Input()
  set addressFormGroupName(addressFormGroupName: string) {
    this._addressFormGroupName = addressFormGroupName;
  }

  get addressFormGroupName(): string { return this._addressFormGroupName; }

  @Input()
  set isoCountries(isoCountries: IsoCountry[]) {
    this._isoCountries = isoCountries;
  }

  get isoCountries(): IsoCountry[] { return this._isoCountries; }

  public postcodeSearchOnClick(): void {
    this.postcodeSearchFormSubmitted = true;

    const postcodeSearchVal = this.postcodeSearchForm.get('postcode').value === null ? '' : this.postcodeSearchForm.get('postcode').value;

    if(this.postcodeSearchForm.valid && postcodeSearchVal.length === 0) {
      this.postcodeSearchResults = [];
      this.showNoAddressFoundMessage = true;
      return;
    }

    if(this.postcodeSearchForm.valid) {
      this.findBtnDisabled = true;
      this._spinnerService.show(this._postcodeSearchSpinnerName);

      const request: GetByPostcodeRequest = {
        postcode: postcodeSearchVal
      };

      this._postcodeSearchService.getByPostcode(request)
        .subscribe(data => {
          this.populatePostcodeSearchResults(data);
          this.showNoAddressFoundMessage = this.postcodeSearchResults.length === 0;
          this.findBtnDisabled = false;
          this._spinnerService.hide(this._postcodeSearchSpinnerName);
        },
        _err => {
          this.findBtnDisabled = false;
          this._spinnerService.hide(this._postcodeSearchSpinnerName);
        });
    }
  }

  public addressListOnChange(event: MatSelectionListChange): void {
    this.postcodeSearchForm.get('postcodeLookupId').setValue(event.options[0].value);
  }
}
