import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConfigService } from './app-config.service';
import { CookieService } from 'ngx-cookie-service';
import { Campaign } from '../models/campaign';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  private readonly _cookieName: string = 'RT_CampaignTracker';
  private readonly _cookieDurationDays: number = 30;
  private readonly _cookieDomain: string;
  
  private _activeCampaignSource: BehaviorSubject<Campaign>;
  
  public readonly activeCampaign: Observable<Campaign>;
  public readonly defaultCampaignId: number;

  constructor(private _appConfigService: AppConfigService,
              private _http: HttpClient,
              private _cookieService: CookieService) {


    this._activeCampaignSource = new BehaviorSubject<Campaign>(null);
    this.activeCampaign = this._activeCampaignSource.asObservable();

    this.defaultCampaignId = this._appConfigService.agent.defaultCampaignId;
    this._cookieDomain = this._appConfigService.agent.cookieDomain;

    this.setActiveCampaign(this.defaultCampaignId, false);

  }

  private setCampaignCookie(campaignId: number): void {
    this._cookieService.set(this._cookieName, campaignId.toString(), this._cookieDurationDays, null, this._cookieDomain, true, 'Strict');
  }

  public isCampaignIdValid(campaignId: number): boolean {
    
    let isValid = false;

    if(this._appConfigService.agent.campaigns.length === 0) return isValid;

    for(let i = 0; i < this._appConfigService.agent.campaigns.length; i++) {
      if(this._appConfigService.agent.campaigns[i].campaignId === campaignId) {
        isValid = true;
        break;
      }
    }

    return isValid;
  }
  
  public isCampaignMagentaValid(campaignId: number): Observable<boolean> {

    const url = `${this._appConfigService.quoteApiBaseUrl}/Codes/IsCampaignIdValid/${this._appConfigService.agentId}/${campaignId}`;

    return this._http.get<boolean>(url);

  }

  public getCampaignById(campaignId: number): Campaign {

    let campaignFound = new Campaign();

    if(this._appConfigService.agent.campaigns.length === 0) return campaignFound;

    for(let i = 0; i < this._appConfigService.agent.campaigns.length; i++) {
      if(this._appConfigService.agent.campaigns[i].campaignId === campaignId) {
        campaignFound = this._appConfigService.agent.campaigns[i];
        break;
      }
    }

    return campaignFound;

  }

  public isCampaignNameValid(campaignName: string): boolean {

    let isValid = false;

    if(campaignName === null) return isValid;

    if(this._appConfigService.agent.campaigns.length === 0) return isValid;

    for(let i = 0; i < this._appConfigService.agent.campaigns.length; i++) {
      if(this._appConfigService.agent.campaigns[i].campaignName.toUpperCase() === campaignName.toUpperCase()) {
        isValid = true;
        break;
      }
    }

    return isValid;
  }

  public getCampaignIdByName(campaignName: string): number {

    let campaignId = -1;

    if(campaignName === null) return campaignId;

    if(this._appConfigService.agent.campaigns.length === 0) return campaignId;

    for(let i = 0; i < this._appConfigService.agent.campaigns.length; i++) {
      if(this._appConfigService.agent.campaigns[i].campaignName.toUpperCase() === campaignName.toUpperCase()) {
        campaignId = this._appConfigService.agent.campaigns[i].campaignId;
        break;
      }
    }

    return campaignId;
  }

  public setActiveCampaign(campaignId: number, setTrackingCookie: boolean): void {

    if(campaignId === this.defaultCampaignId) {

      this._activeCampaignSource.next(this.getCampaignById(this.defaultCampaignId));

    } else {

      // Check if campaign is a valid Magenta configured campaign.
      this.isCampaignMagentaValid(campaignId)
        .subscribe(isValid => {
          if(isValid) {

            // Check the campaign is configured locally.
            if(this.isCampaignIdValid(campaignId)) {

              this._activeCampaignSource.next(this.getCampaignById(campaignId));

              // This isn't the default campaign so set cookie if required.
              if(setTrackingCookie) this.setCampaignCookie(campaignId);
            }
          }
        });

    }

  }

  /**
   * Sets the published campaign for promotion code inputs.
   * Differs from regular setActiveCampaign method because it assumes
   * all relevant validation checks are performed before setting.
   * Required due to regular set invoking API call potentially delaying
   * set. When using this method, isCampaignMagentaValid method should
   * always be called beforehand.
   */
  public setActiveCampaignForPromoCode(campaignId: number): void {
    this._activeCampaignSource.next(this.getCampaignById(campaignId));
    this.setCampaignCookie(campaignId);
  }

  public isCampaignCookieSet(): boolean {

    return this._cookieService.check(this._cookieName);

  }

  public getPreviouslySetCampaignId(): number {
    
    let cookieValue: number = 0;

    if(this.isCampaignCookieSet()) {
      
      const x = Number(this._cookieService.get(this._cookieName));

      if(!isNaN(x)) {
        cookieValue = x;
      }

    }

    return cookieValue;
  }
  
}
